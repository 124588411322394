import gql from "graphql-tag";

export const ADD_SINGLE_EXPENSE = gql`
  mutation AddSingleExpense(    
    $expenseReportId: Int!
    $name: String!
    $date: String!
    $location: String
    $amount: Float
    $tva: Float
    $type: String
    $comment: String
    $receipt: Upload
  ) {
    addSingleExpense(
      expenseReportId: $expenseReportId
      name: $name
      date: $date
      location: $location
      amount: $amount
      tva: $tva
      type: $type
      comment: $comment
      receipt: $receipt
    ) {
      id
      name
      date
      location
      amount
      tva
      type
      comment
      receipt
      receiptName
      receiptType
    }
  }
`;

export const EDIT_SINGLE_EXPENSE = gql`
  mutation EditSingleExpense(    
    $id: Int!
    $name: String!
    $date: String!
    $location: String
    $amount: Float
    $tva: Float
    $type: String
    $comment: String
    $receipt: Upload
  ) {
    editSingleExpense(
      id: $id
      name: $name
      date: $date
      location: $location
      amount: $amount
      tva: $tva
      type: $type
      comment: $comment
      receipt: $receipt
    ) {
      id
      name
      date
      location
      amount
      tva
      type
      comment
      receipt
      receiptName
      receiptType
    }
  }
`;

export const ACCEPT_EXPENSE = gql`
  mutation AcceptExpense(
    $id: Int!
    $commentaireByFirstApproval: String) {
    accept(
      id: $id 
      commentaireByFirstApproval:$commentaireByFirstApproval
    ) {
      id
      placementId
      clientExpense {
        id
        email
        firstName
        lastName
      }
      freelancerExpense {
        id
        email
        firstName
        lastName
      }
      firstApproval {
        id
        email
        firstName
        lastName
      }
      title
      comment
      totalAmount
      totalTVA
      status
      createdAt
      commentaireByFirstApproval
    }
  }
`;

export const REJECT_EXPENSE = gql`
  mutation RejectExpense(
    $id: Int!
    $commentaireByFirstApproval: String) {
    reject(
      id: $id 
      commentaireByFirstApproval:$commentaireByFirstApproval
    ) {
      id
      placementId
      clientExpense {
        id
        email
        firstName
        lastName
      }
      freelancerExpense {
        id
        email
        firstName
        lastName
      }
      firstApproval {
        id
        email
        firstName
        lastName
      }
      title
      comment
      totalAmount
      totalTVA
      status
      createdAt
      commentaireByFirstApproval
    }
  }
`;