import gql from "graphql-tag";
export const SEND_EXPENSE = gql`
  mutation SendExpense($id: Int!, $commentaireByFreelancer: String) {
    send(id: $id, commentaireByFreelancer: $commentaireByFreelancer) {
      id
      placementId
      clientExpense {
        id
        email
        firstName
        lastName
      }
      freelancerExpense {
        id
        email
        firstName
        lastName
      }
      firstApproval {
        id
        email
        firstName
        lastName
      }
      title
      comment
      totalAmount
      totalTVA
      status
      createdAt
      commentaireByFreelancer
    }
  }
`;

export const CREATE_EXPENSE = gql`
  mutation CreateExpense($title: String, $placementId: Int, $date: String) {
    create(title: $title, placementId: $placementId, date: $date) {
      id
      placementId
      title
      comment
      totalAmount
      totalTVA
      status
      createdAt
    }
  }
`;
