import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  HeaderV2,
  ItemMenu,
  MenuDashBoard,
  MenuDashBoardV2,
  Select,
  SwitchButton3
} from "cf-neo-ui";
import ResponsiveHeaderV2 from "cf-neo-ui/lib/header-v2/src/ResponsiveHeaderV2";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import Routes from "../routes";
import classes from "./styles.module.scss";
import { LOGOUT_MUTATION } from "./mutation";
import menuItems from "../../../configs/menuItems";
import domaineExpertise from "../../../configs/domaineExpertise";
@inject(
  "sessionStore",
  "appStore",
  "postANeedStore",
  "candidatesSearchStore",
  "missionsSearchStore"
)
@observer
class HeaderFr extends Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;

    this.state = {
      canSearch: false,
      lastFirstChoiceOption: "",
      lastSecondChoiceOption: "",
      firstWasUpdated: false,
      secondWasUpdated: false,
      placeholderText: [
        i18n._(t`Je suis`),
        i18n._(t`IT ou Finance`),
        i18n._(t`Je cherche`),
        i18n._(t`Freelance ou CDI`)
      ],
      options: [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        }
      ]
    };
  }

  logoutClickHandler = () => {
    const {
      sessionStore,
      history,
      postANeedStore,
      candidatesSearchStore,
      client,
      apolloClient
    } = this.props;
    const { Home } = Routes.HbfLayoutRoutes;
    postANeedStore.reset();
    apolloClient.mutate({
      mutation: LOGOUT_MUTATION,
      variables: { token: sessionStore.authToken }
    });
    sessionStore.logout();
    candidatesSearchStore.clearSearch();
    client.cache.reset();
    history.push(Home.path);
  };

  handleSelectLanguage = val => {
    const { appStore } = this.props;
    const { changeLanguage } = appStore;

    if (val.text == "En") {
      changeLanguage("en");
    } else if (val.text == "Fr") {
      changeLanguage("fr");
    }
  };

  handleSelectLanguageSwitch = val => {
    const { appStore } = this.props;
    const { changeLanguage } = appStore;

    if (val == 2) {
      changeLanguage("en");
    } else {
      changeLanguage("fr");
    }
  };

  getHeaderProps = currentLanguage => {
    const { history, appStore, i18n, missionsSearchStore } = this.props;
    const { Home } = Routes.HbfLayoutRoutes;
    const widthScreen = appStore.width <= 1024;

    return {
      logoSrc: "/assets/images/logo_mind_quest.webp",
      onClickLogo: () => history.push(Home.path),
      options: this.state.options,
      logoAlt: "mindquest logo",
      className: `layout-header`,
      style: { width: widthScreen ? "100%" : "100%", "align-items": "center" },
      menuItems: menuItems(history, i18n, currentLanguage, missionsSearchStore),
      inWhichOptions: this.state.options.length,
      nbOptions: 4,
      canSearch: this.state.canSearch,
      placeholder: this.state.placeholderText,
      placeholderText: i18n._(t`Recherche assitée`)
    };
  };

  firstSelct = choice => {
    const { i18n } = this.props;

    /* 1st select except "Un apprenant"*/
    if (
      choice &&
      (choice[0]?.value == "Candidat" ||
        choice[0]?.value == "Une entreprise / Un recruteur")
    ) {
      let newOptions = [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        },
        {
          selectOption: [
            { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
            { value: "Finance", text: i18n._(t`Finance`) }
          ],
          placeholder: i18n._(t`IT ou Finance`),
          checked: false
        }
      ];

      this.setState({
        options: newOptions, // update options
        lastFirstChoiceOption: choice[0]?.value, // update firstChoice
        placeholderText: [
          choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
          choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
          i18n._(t`Je cherche`),
          i18n._(t`Freelance ou CDI`)
        ] // update placeholder in the select
      });
    }

    /* 1st select with choice "Un apprenant" */
    if (choice && choice[0]?.value == "Un apprenant") {
      let newOptions = [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        },
        {
          selectOption: [{ value: "Finance", text: i18n._(t`Finance`) }],
          placeholder: i18n._(t`IT ou Finance`),
          checked: false
        }
      ];

      this.setState({
        options: newOptions, // update options
        lastFirstChoiceOption: choice[0]?.value, // update firstChoice
        placeholderText: [
          choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
          choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
          i18n._(t`Je cherche`),
          i18n._(t`Freelance ou CDI`)
        ] // update placeholder in the select
      });
    }
  };

  firstSelctUpdated = choice => {
    const { i18n } = this.props;

    /* 1st select except "Un apprenant"*/
    if (
      choice &&
      (choice[0]?.value == "Candidat" ||
        choice[0]?.value == "Une entreprise / Un recruteur")
    ) {
      let newOptions = [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        },
        {
          selectOption: [
            { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
            { value: "Finance", text: i18n._(t`Finance`) }
          ],
          placeholder: i18n._(t`IT ou Finance`),
          checked: false
        }
      ];

      this.setState({
        options: newOptions, // update options
        firstWasUpdated: true,
        lastFirstChoiceOption: choice[0]?.value, // update firstChoice
        placeholderText: [
          choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
          i18n._(t`IT ou Finance`),
          i18n._(t`Je cherche`),
          i18n._(t`Freelance ou CDI`)
        ] // update placeholder in the select
      });
    }

    /* 1st select with choice "Un apprenant" */
    if (choice && choice[0]?.value == "Un apprenant") {
      let newOptions = [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        },
        {
          selectOption: [{ value: "Finance", text: i18n._(t`Finance`) }],
          placeholder: i18n._(t`IT ou Finance`),
          checked: false
        }
      ];
      this.setState({
        options: newOptions, // update options
        firstWasUpdated: true,
        lastFirstChoiceOption: choice[0]?.value, // update firstChoice
        placeholderText: [
          choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
          i18n._(t`IT ou Finance`),
          i18n._(t`Je cherche`),
          i18n._(t`Freelance ou CDI`)
        ] // update placeholder in the select
      });
    }
  };

  secondSelect = choice => {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    /** 2nd select */
    if (
      choice &&
      choice[0] &&
      choice[0]?.value &&
      choice[1] &&
      choice[1]?.value
    ) {
      /** 1st choice 'candidat' in 1st select with second select*/
      if (
        choice[0]?.value == "Candidat" &&
        (choice[1]?.value == "IT / Digital" || choice[1]?.value == "Finance")
      ) {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Une mission", text: i18n._(t`Une mission`) },
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`Je cherche`),
            checked: false
          }
        ];

        this.setState({
          options: newOptions,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),
            i18n._(t`Freelance ou CDI`)
          ]
        });
      }
      /** 2nd choice 'Une entreprise / Un recruteur' in 1st select with second select*/
      if (
        choice[0]?.value == "Une entreprise / Un recruteur" &&
        (choice[1]?.value == "IT / Digital" || choice[1]?.value == "Finance")
      ) {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Un talent", text: i18n._(t`Un talent`) },
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          }
        ];

        this.setState({
          options: newOptions,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),
            // i18n._(t`Je cherche`),
            i18n._(t`Freelance ou CDI`)
          ]
        });
      }

      /** 3rd choice 'Une entreprise / Un recruteur' in 1st select with with second select*/
      if (choice[0]?.value == "Un apprenant" && choice[1]?.value == "Finance") {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`Je cherche`),
            checked: false
          }
        ];

        this.setState({
          options: newOptions,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),
            i18n._(t`Freelance ou CDI`)
          ]
        });
      }
    }
  };

  secondSelectUpdated = choice => {
    const { i18n } = this.props;

    /** 2nd select */
    this.setState({ lastSecondChoiceOption: "" });
    if (
      choice &&
      choice[0] &&
      choice[0]?.value &&
      choice[1] &&
      choice[1]?.value
    ) {
      /** 1st choice 'candidat' or 2nd choice 'Une entreprise / Un recruteur' in 1st select with second select*/
      if (
        choice[0]?.value == "Candidat" &&
        (choice[1]?.value == "IT / Digital" || choice[1]?.value == "Finance")
      ) {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Une mission", text: i18n._(t`Une mission`) },
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`Je cherche`),
            checked: false
          }
        ];

        this.setState({
          options: newOptions,
          secondWasUpdated: true,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            i18n._(t`Je cherche`),
            i18n._(t`Freelance ou CDI`)
          ]
        });
      }
      /** 1st choice 'candidat' or 2nd choice 'Une entreprise / Un recruteur' in 1st select with second select*/
      if (
        choice[0]?.value == "Une entreprise / Un recruteur" &&
        (choice[1]?.value == "IT / Digital" || choice[1]?.value == "Finance")
      ) {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Un talent", text: i18n._(t`Un talent`) },
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          }
        ];
        this.setState({
          options: newOptions,
          secondWasUpdated: true,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            i18n._(t`Je cherche`),
            i18n._(t`Freelance ou CDI`)
          ]
        });
      }

      /** 3rd choice 'Une entreprise / Un recruteur' in 1st select with with second select*/
      if (choice[0]?.value == "Un apprenant" && choice[1]?.value == "Finance") {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`Je cherche`),
            checked: false
          }
        ];

        this.setState({
          options: newOptions,
          secondWasUpdated: true,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            i18n._(t`Je cherche`),
            i18n._(t`Freelance ou CDI`)
          ]
        });
      }
    }
  };
  thirdSelectUpdated = choice => {
    const { i18n } = this.props;

    /** 2nd select */
    this.setState({ lastSecondChoiceOption: "" });
    if (
      choice &&
      choice[0] &&
      choice[0]?.value &&
      choice[1] &&
      choice[1]?.value &&
      choice[2] &&
      choice[2]?.value
    ) {
      /** 1st choice 'candidat' or 2nd choice 'Une entreprise / Un recruteur' in 1st select with second select*/
      if (
        choice[0]?.value == "Candidat" &&
        (choice[1]?.value == "IT / Digital" || choice[1]?.value == "Finance")
      ) {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Une mission", text: i18n._(t`Une mission`) },
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`Je cherche`),
            checked: false
          },
          {
            selectOption: [
              { value: "Freelance", text: i18n._(t`Freelance`) },
              { value: "CDI", text: i18n._(t`CDI`) },
              { value: "Indifferent", text: i18n._(t`Indifferent`) }
            ],
            placeholder: i18n._(t`Freelance ou CDI`),
            checked: false
          }
        ];

        this.setState({
          options: newOptions,
          secondWasUpdated: true,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),

            i18n._(t`Freelance ou CDI`)
          ]
        });
      }
      /** 1st choice 'candidat' or 2nd choice 'Une entreprise / Un recruteur' in 1st select with second select*/
      if (
        choice[0]?.value == "Une entreprise / Un recruteur" &&
        (choice[1]?.value == "IT / Digital" || choice[1]?.value == "Finance")
      ) {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Un talent", text: i18n._(t`Un talent`) },
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Freelance", text: i18n._(t`Freelance`) },
              { value: "CDI", text: i18n._(t`CDI`) },
              { value: "Indifferent", text: i18n._(t`Indifferent`) }
            ],
            placeholder: i18n._(t`Freelance ou CDI`),
            checked: false
          }
        ];
        this.setState({
          options: newOptions,
          secondWasUpdated: true,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),

            i18n._(t`Freelance ou CDI`)
          ]
        });
      }

      /** 3rd choice 'Une entreprise / Un recruteur' in 1st select with with second select*/
      if (choice[0]?.value == "Un apprenant" && choice[1]?.value == "Finance") {
        let newOptions = [
          {
            selectOption: [
              { value: "Candidat", text: i18n._(t`Candidat`) },
              {
                value: "Une entreprise / Un recruteur",
                text: i18n._(t`Une entreprise / Un recruteur`)
              },
              { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
            ],
            placeholder: "Je suis",
            checked: false
          },
          {
            selectOption: [
              { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
              { value: "Finance", text: i18n._(t`Finance`) }
            ],
            placeholder: i18n._(t`IT ou Finance`),
            checked: false
          },
          {
            selectOption: [
              { value: "Du contenu", text: i18n._(t`Du contenu`) }
            ],
            placeholder: i18n._(t`Je cherche`),
            checked: false
          },
          {
            selectOption: [
              { value: "Freelance", text: i18n._(t`Freelance`) },
              { value: "CDI", text: i18n._(t`CDI`) },
              { value: "Indifferent", text: i18n._(t`Indifferent`) }
            ],
            placeholder: i18n._(t`Freelance ou CDI`),
            checked: false
          }
        ];

        this.setState({
          options: newOptions,
          secondWasUpdated: true,
          lastSecondChoiceOption: choice[1]?.value, // update SecondChoice
          placeholderText: [
            choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
            choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
            choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),

            i18n._(t`Freelance ou CDI`)
          ]
        });
      }
    }
  };

  thirdSelect = choice => {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;

    if (
      choice &&
      choice[0] &&
      choice[0].value &&
      choice[1] &&
      choice[1]?.value &&
      choice[2] &&
      choice[2]?.value
    ) {
      let newOptions = [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        },
        {
          selectOption: [
            { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
            { value: "Finance", text: i18n._(t`Finance`) }
          ],
          placeholder: i18n._(t`IT ou Finance`),
          checked: false
        },
        {
          selectOption: [
            { value: "Une mission", text: i18n._(t`Une mission`) },
            { value: "Du contenu", text: i18n._(t`Du contenu`) }
          ],
          placeholder: i18n._(t`Je cherche`),
          checked: false
        },
        {
          selectOption: [
            { value: "Freelance", text: i18n._(t`Freelance`) },
            { value: "CDI", text: i18n._(t`CDI`) },
            { value: "Indifferent", text: i18n._(t`Indifferent`) }
          ],
          placeholder: i18n._(t`Freelance ou CDI`),
          checked: false
        }
      ];

      this.setState({
        options: newOptions,
        // canSearch: true, // i comment this to resolve NEOS-4406
        placeholderText: [
          choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
          choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
          choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),
          i18n._(t`Freelance ou CDI`)
        ]
      });
    }
  };

  fourthSelectUpdated = choice => {
    const { i18n } = this.props;

    /** 2nd select */
    this.setState({ lastSecondChoiceOption: "" });
    if (
      choice &&
      choice[0] &&
      choice[0].value &&
      choice[1] &&
      choice[1]?.value &&
      choice[2] &&
      choice[2]?.value &&
      choice[3] &&
      choice[3]?.value
    ) {
      let newOptions = [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        },
        {
          selectOption: [
            { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
            { value: "Finance", text: i18n._(t`Finance`) }
          ],
          placeholder: i18n._(t`IT ou Finance`),
          checked: false
        },
        {
          selectOption: [
            { value: "Une mission", text: i18n._(t`Une mission`) },
            { value: "Du contenu", text: i18n._(t`Du contenu`) }
          ],
          placeholder: i18n._(t`Je cherche`),
          checked: false
        },
        {
          selectOption: [
            { value: "Freelance", text: i18n._(t`Freelance`) },
            { value: "CDI", text: i18n._(t`CDI`) },
            { value: "Indifferent", text: i18n._(t`Indifferent`) }
          ],
          placeholder: i18n._(t`Freelance ou CDI`),
          checked: false
        }
      ];

      this.setState({
        options: newOptions,
        canSearch: true,
        placeholderText: [
          choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
          choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
          choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),
          choice[3]?.text ? choice[3]?.text : i18n._(t`Freelance ou CDI`)
        ]
      });
    }
  };
  forthSelect = choice => {
    const { i18n } = this.props;
    if (
      choice &&
      choice[0] &&
      choice[0].value &&
      choice[1] &&
      choice[1]?.value &&
      choice[2] &&
      choice[2]?.value &&
      choice[3] &&
      choice[3]?.value
    ) {
      let newOptions = [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        },
        {
          selectOption: [
            { value: "IT / Digital", text: i18n._(t`IT / Digital`) },
            { value: "Finance", text: i18n._(t`Finance`) }
          ],
          placeholder: i18n._(t`IT ou Finance`),
          checked: false
        },
        {
          selectOption: [
            { value: "Une mission", text: i18n._(t`Une mission`) },
            { value: "Du contenu", text: i18n._(t`Du contenu`) }
          ],
          placeholder: i18n._(t`Je cherche`),
          checked: false
        },
        {
          selectOption: [
            { value: "Freelance", text: i18n._(t`Freelance`) },
            { value: "CDI", text: i18n._(t`CDI`) },
            { value: "Indifferent", text: i18n._(t`Indifferent`) }
          ],
          placeholder: i18n._(t`Freelance ou CDI`),
          checked: false
        }
      ];

      this.setState({
        options: newOptions,
        canSearch: true,
        placeholderText: [
          choice[0]?.text ? choice[0]?.text : i18n._(t`Je suis`),
          choice[1]?.text ? choice[1]?.text : i18n._(t`IT ou Finance`),
          choice[2]?.text ? choice[2]?.text : i18n._(t`Je cherche`),
          choice[3]?.text ? choice[3]?.text : i18n._(t`Freelance ou CDI`)
        ]
      });
    }
  };

  selectedChoice = choice => {
    if (
      this.state.lastFirstChoiceOption &&
      this.state.lastFirstChoiceOption != "" &&
      choice &&
      choice[0] &&
      this.state.lastFirstChoiceOption != choice[0]?.value
    ) {
      this.firstSelctUpdated(choice);
    } else if (
      this.state.lastSecondChoiceOption &&
      this.state.lastSecondChoiceOption != "" &&
      choice &&
      choice[1] &&
      this.state.lastSecondChoiceOption != choice[1]?.value
    ) {
      this.secondSelectUpdated(choice);
    } else if (
      this.state.lastFirstChoiceOption &&
      this.state.lastFirstChoiceOption != "" &&
      this.state.firstWasUpdated
    ) {
      this.firstSelctUpdated(choice);
      this.secondSelectUpdated(choice);
      this.thirdSelectUpdated(choice);
      this.fourthSelectUpdated(choice);
    } else {
      this.firstSelct(choice);
      this.secondSelect(choice);
      this.thirdSelect(choice);
      this.forthSelect(choice);
    }
  };

  renderHeader = () => {
    const {
      history,
      sessionStore,
      appStore,
      i18n,
      missionsSearchStore
    } = this.props;
    const { HbLayoutRoutes, LpbLayoutRoutes } = Routes;
    const { SignUp, Login } = HbLayoutRoutes;
    const { currentLanguage } = appStore;
    const { pictureUrl, profilePhoto } = sessionStore;

    let user;
    if (sessionStore.account) {
      user = sessionStore.account.role;
    } else {
      user = "NOT CONNECTED";
    }

    return (
      <HeaderV2
        {...this.getHeaderProps(currentLanguage)}
        className={classes.headerV2}
        onSearch={this.getLink}
        onSelectedChoice={this.selectedChoice}
      >
        <div>
          {sessionStore.authToken && user === "CANDIDATE" && (
            <div className={classes.headerRightBtns}>
              <Select
                onSelect={this.handleSelectLanguage}
                className={classes.selectStyle}
                border="none"
                options={{
                  groupA: [
                    {
                      text: i18n._(t`Fr`),
                      value: 0,
                      selected: currentLanguage === "fr"
                    },
                    {
                      text: i18n._(t`En`),
                      value: 1,
                      selected: currentLanguage === "en"
                    }
                  ]
                }}
              />

              <MenuDashBoard
                userName="User Name"
                percentage={null}
                dimension={64}
                style={{ display: "flex" }}
                image={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
              >
                <ItemMenu
                  label={i18n._(t`Profile`)}
                  value={1}
                  onClick={() => history.push(LpbLayoutRoutes.MyProfile.path)}
                />
                <ItemMenu
                  label={i18n._(t`Se déconnecter`)}
                  value={1}
                  onClick={this.logoutClickHandler}
                />
              </MenuDashBoard>
            </div>
          )}
          {sessionStore.authToken && user === "CLIENT" && (
            <div className={classes.headerRightBtns}>
              <Select
                className={classes.selectStyle}
                border="none"
                id="language"
                options={{
                  groupA: [
                    {
                      text: i18n._(t`Fr`),
                      value: 0,
                      selected: currentLanguage === "fr"
                    },
                    {
                      text: i18n._(t`En`),
                      value: 1,
                      selected: currentLanguage === "en"
                    }
                  ]
                }}
                onSelect={this.handleSelectLanguage}
              />
              <MenuDashBoard
                userName="User Name"
                percentage={null}
                style={{ display: "flex" }}
                dimension={64}
                image={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
              >
                <ItemMenu
                  label={i18n._(t`Profile`)}
                  value={1}
                  onClick={() => history.push(LpbLayoutRoutes.MyProfile.path)}
                />
                <ItemMenu
                  label={i18n._(t`Se déconnecter`)}
                  value={1}
                  onClick={this.logoutClickHandler}
                />
              </MenuDashBoard>
            </div>
          )}

          {!sessionStore.authToken && (
            <div className={classes.headerRightBtns}>
              <Select
                className={classes.selectStyle}
                border="none"
                id="language"
                options={{
                  groupA: [
                    {
                      text: i18n._(t`Fr`),
                      value: 0,
                      selected: currentLanguage === "fr"
                    },
                    {
                      text: i18n._(t`En`),
                      value: 1,
                      selected: currentLanguage === "en"
                    }
                  ]
                }}
                onSelect={this.handleSelectLanguage}
              />
              <MenuDashBoard
                userName="User Name"
                percentage={null}
                style={{ display: "flex" }}
                dimension={64}
                image="/defaultAvatar.webp"
              >
                <ItemMenu
                  label={i18n._(t`Se connecter`)}
                  value={1}
                  onClick={() => history.push(Login.path)}
                />
                <ItemMenu
                  label={i18n._(t`S'inscrire`)}
                  value={1}
                  onClick={() => history.push(`${SignUp.path}?type=consultant`)}
                />
              </MenuDashBoard>
            </div>
          )}
        </div>
      </HeaderV2>
    );
  };

  getLink = choices => {
    const { history, appStore, missionsSearchStore, i18n } = this.props;
    const { currentLanguage } = appStore;
    const {
      changeEmploymentType,
      addCategory,
      initCategories
    } = missionsSearchStore;
    if (Object.keys(choices).length > 0) {
      if (
        choices[0].value === "Candidat" &&
        choices[1].value === "IT / Digital"
      ) {
        if (choices[2].value === "Une mission") {
          if (choices[3] && choices[3].value) {
            if (choices[3].value == "Indifferent") {
              history.push("/missions");
              changeEmploymentType("");
              initCategories();
            } else if (choices[3].value == "Freelance") {
              history.push("/missions?employmentType=Contract");
              changeEmploymentType("Contract");
              initCategories();
            } else if (choices[3].value == "CDI") {
              history.push("/missions?employmentType=Permanent");
              changeEmploymentType("Permanent");
              initCategories();
            }
          } else {
            history.push("/missions");
            initCategories();

            changeEmploymentType("");
          }
        } else {
          currentLanguage === "fr"
            ? history.push("/blog/freelance-tech")
            : history.push("/blog/it-consultant");
        }
      }

      if (choices[0].value === "Candidat" && choices[1].value === "Finance") {
        if (choices[2].value === "Une mission") {
          if (choices[3] && choices[3].value) {
            if (choices[3].value == "Freelance") {
              history.push(
                "/missions?categories=Finance&employmentType=Contract"
              );
              addCategory(domaineExpertise(i18n, "Finance", "key"));
              changeEmploymentType("Contract");
            } else if (choices[3].value == "CDI") {
              history.push(
                "/missions?categories=Finance&employmentType=Permanent"
              );
              addCategory(domaineExpertise(i18n, "Finance", "key"));
              changeEmploymentType("Permanent");
            } else {
              history.push("/missions?categories=Finance");
              changeEmploymentType("");
              addCategory(domaineExpertise(i18n, "Finance", "key"));
            }
          } else {
            history.push("/missions?categories=Finance");
            addCategory(domaineExpertise(i18n, "Finance", "key"));
          }
        } else {
          history.push("/blog/finance-professionals");
        }
      }

      if (
        choices[0].value === "Une entreprise / Un recruteur" &&
        choices[1].value === "IT / Digital"
      ) {
        choices[2].value === "Un talent"
          ? history.push("/candidates")
          : history.push("/blog/it-decision-makers");
      }

      if (
        choices[0].value === "Une entreprise / Un recruteur" &&
        choices[1].value === "Finance"
      ) {
        choices[2].value === "Un talent"
          ? history.push("/candidates")
          : history.push("/blog");
      }

      if (
        choices[0].value === "Un apprenant" &&
        choices[1].value === "Finance" &&
        choices[2].value === "Du contenu"
      ) {
        history.push("/blog/finance-professionals");
      }
    }
  };

  renderResponsiveHeader = () => {
    const { history, sessionStore, appStore, i18n } = this.props;
    const { currentLanguage } = appStore;
    const { HbLayoutRoutes, LpbLayoutRoutes } = Routes;
    const { SignUp, Login } = HbLayoutRoutes;
    const { pictureUrl, profilePhoto } = sessionStore;

    let user;
    if (sessionStore.account) {
      user = sessionStore.account.role;
    } else {
      user = "NOT CONNECTED";
    }
    return (
      <div className={classes.responsiveHeaderWrapper}>
        <ResponsiveHeaderV2
          {...this.getHeaderProps(currentLanguage)}
          onSearch={this.getLink}
          onSelectedChoice={this.selectedChoice}
          returnText={i18n._(t`Retour`)}
        >
          <div className={classes.responsiveHeaderRightBtns}>
            {sessionStore.authToken && user === "CANDIDATE" && (
              <div className={classes.children}>
                <SwitchButton3
                  className={classes.SwitchButton3Style}
                  defaultTab={currentLanguage === "fr" ? 1 : 2}
                  SegmentText1={i18n._(t`FR`)}
                  SegmentText2={i18n._(t`EN`)}
                  onClick={() =>
                    this.handleSelectLanguageSwitch(
                      SwitchButton3.getSelectedTab()
                    )
                  }
                />

                <MenuDashBoardV2
                  percentage={null}
                  image={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
                ></MenuDashBoardV2>
                <a
                  className={classes.profile}
                  onClick={() => history.push(LpbLayoutRoutes.MyProfile.path)}
                >
                  <Trans>Profile</Trans>
                </a>

                <span
                  className={classes.logoutSpan}
                  onClick={this.logoutClickHandler}
                >
                  <Trans>Se déconnecter</Trans>
                </span>
              </div>
            )}

            {sessionStore.authToken && user === "CLIENT" && (
              <div className={classes.children}>
                <SwitchButton3
                  className={classes.SwitchButton3Style}
                  defaultTab={currentLanguage === "fr" ? 1 : 2}
                  SegmentText1={i18n._(t`FR`)}
                  SegmentText2={i18n._(t`EN`)}
                  onClick={() =>
                    this.handleSelectLanguageSwitch(
                      SwitchButton3.getSelectedTab()
                    )
                  }
                />

                <MenuDashBoardV2
                  percentage={null}
                  image={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
                ></MenuDashBoardV2>
                <a
                  className={classes.profile}
                  onClick={() => history.push(LpbLayoutRoutes.MyProfile.path)}
                >
                  <Trans>Profile</Trans>
                </a>

                <span
                  className={classes.logoutSpan}
                  onClick={this.logoutClickHandler}
                >
                  <Trans>Se déconnecter</Trans>
                </span>
              </div>
            )}

            {!sessionStore.authToken && (
              <div className={classes.children}>
                <SwitchButton3
                  className={classes.SwitchButton3Style}
                  defaultTab={currentLanguage === "fr" ? 1 : 2}
                  SegmentText1={i18n._(t`FR`)}
                  SegmentText2={i18n._(t`EN`)}
                  onClick={() =>
                    this.handleSelectLanguageSwitch(
                      SwitchButton3.getSelectedTab()
                    )
                  }
                />

                <MenuDashBoardV2
                  percentage={null}
                  image={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
                ></MenuDashBoardV2>
                <a
                  className={classes.profile}
                  onClick={() => history.push(Login.path)}
                >
                  <Trans>Se connecter</Trans>
                </a>

                <span
                  className={classes.logoutSpan}
                  onClick={() => history.push(`${SignUp.path}?type=consultant`)}
                >
                  <Trans>S'inscrire</Trans>
                </span>
              </div>
            )}
          </div>
        </ResponsiveHeaderV2>
      </div>
    );
  };

  render() {
    const { appStore, missionsSearchStore } = this.props;
    return appStore.width <= 1510
      ? this.renderResponsiveHeader()
      : this.renderHeader();
  }
}

HeaderFr.wrappedComponent.propTypes = {
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    logout: PropTypes.func,
    account: PropTypes.shape({
      role: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    authToken: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    reset: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number,
    changeLanguage: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  client: PropTypes.shape({
    cache: PropTypes.shape({
      reset: PropTypes.func
    })
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    changeEmploymentType: PropTypes.func,
    employmentType: PropTypes.string
  }).isRequired
};

export default withApollo(withI18n()(withRouter(HeaderFr)));
