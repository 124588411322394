import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "./ExpenseCard.css";
import { Button } from "cf-neo-ui";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { formatDate } from "../helpers/formatDate";
import { getTypeInTheRightLanguage } from "../helpers/getTypeInTheRightLanguage";

const ExpenseCard = ({
  id,
  name,
  date,
  amount,
  tva,
  type,
  receiptName,
  receipt,
  receiptType,
  role,
  status,
  i18n,
  history,
  onEditClick
}) => {
  const [iconColor, setIconColor] = useState(receiptName ? "#0BC46E" : "#C40B24");
  const [iconClass, setIconClass] = useState(receiptName ? "fa-check-circle" : "fa-times-circle");
  const [formattedDate, setFormattedDate] = useState(date ? formatDate(date, i18n._(t`fr`)) : "");

  useEffect(() => {
    setIconColor(receiptName ? "#0BC46E" : "#C40B24");
    setIconClass(receiptName ? "fa-check-circle" : "fa-times-circle");
    setFormattedDate(date ? formatDate(date, i18n._(t`fr`)) : "");
  }, [date, i18n, receiptName]);

  const buttonStyles = {
    width: "140px",
    right: "22px",
    top: "38px",
    height: "41px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  };

  const renderDownloadLink = () => {
    return (
      <a
        href={`data:${receiptType};base64,${receipt}`}
        download={receiptName}
        className="no-design-link"
      >
        <Trans>Voir le reçu</Trans>
      </a>
    );
  };
  return (
    <div>
      <div className="depense">
        <div className="horizontal-line"></div>

        <span className="depense-name">{name}</span>
        <div>
          {role === "CLIENT" ? (
            receiptName ? (
              <Button variant="secondary" style={buttonStyles}>
                {renderDownloadLink()}
              </Button>
            ) : (
              <Button variant="secondary" style={buttonStyles} disabled>
                <Trans>Voir le reçu</Trans>
              </Button>
            )
          ) : (
            (status === "Draft" || status === "Rejected") && (
              <Button
                variant="secondary"
                style={buttonStyles}
                onClick={() =>
                  onEditClick({
                    id,
                    name,
                    date,
                    amount,
                    tva,
                    type,
                    receiptName
                  })
                }
              >
                <Trans>Editer</Trans>
              </Button>
            )
          )}
          <span className="depense-receipt">
            <Trans>Reçu</Trans>&nbsp;
            <i className={`fa ${iconClass}`} style={{ color: iconColor }}></i>
          </span>
        </div>
        <span className="depense-date">{formattedDate}</span>
        <span className="depensetva">TVA {tva}€</span>
        <div className="theme-selector">
          <span className="depense-type">
            {getTypeInTheRightLanguage(type, i18n)}
          </span>
        </div>
        <span className="depense-amount">{amount}€</span>
      </div>
    </div>
  );
};

ExpenseCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  date: PropTypes.string,
  amount: PropTypes.number,
  tva: PropTypes.number,
  type: PropTypes.string,
  receipt: PropTypes.string,
  receiptName: PropTypes.string,
  receiptType: PropTypes.string,
  role: PropTypes.string,
  status: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  i18n: PropTypes.shape({
    _: PropTypes.func
  }),
  onEditClick: PropTypes.func
};

export default withI18n()(withRouter(ExpenseCard));
