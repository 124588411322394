import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import PropTypes, { any } from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Icon,
  TextInputV2,
  InputPhoneNumber,
  Validation,
  FormGroup,
  toaster,
  Spinner,
  Button,
  TextInput
} from "cf-neo-ui";
import theme from "../../configs/theme";
import validate from "../../utils/validators";
import { Trans, t } from "@lingui/macro";
import { ApolloConsumer, Mutation } from "react-apollo";
import { SEND_TALENT_INTERNAL_NOTIFICATION_MUTATION } from "../../containers/search-candidates-page/SearchCandidatesBody/candidates-list/mutation";
import { inject, observer } from "mobx-react";

import "./ModalSendInternalNotifications.scss";

import countriesData from "../../helpers/countriesData";

@inject("sessionStore", "appStore")
@observer
class ModalSendInternalNotifications extends Component {
  constructor(props) {
    super(props);


    this.state = {
      isValidPhoneNumber: {
        status: true,
        msg: ""
      },
      name: "",
      email: "",
      isValidName: {
        status: true,
        msg: ""
      },
      isValidEmail: {
        status: true,
        msg: ""
      },
      currentValueInput: {
        flag: "./flags/fr.svg",
        countryName: "France",
        codeCountry: "fr",
        codePhoneNumber: "+33",
        formatPhoneNumber: "1234567891",
        placeholder: "12 3456 7891",
        phoneNumber: ""
      }
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  mutationCompleted = () => {
    const { i18n, closeModalSendInternalNotifications, authToken } = this.props;
    const valid = !authToken ? this.isFormValid() : true;
    if (!valid) return;
    closeModalSendInternalNotifications();
    toaster.success({
      title: i18n._(t`Email Envoyé`),
      description: i18n._(t`Votre Suggestion sera revue`)
    });
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const { clientName, clientEmail, clientPhoneNumber } = sessionStore;
    let valid = true;

    if (!this.validateClientName(clientName)) valid = false;
    if (!this.validateClientEmail(clientEmail)) valid = false;
    if (!this.validateClientPhoneNumber(clientPhoneNumber)) valid = false;

    if (!valid) return false;

    return valid;
  }

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  selectCountrie = data => {
    const {
      flag,
      countryName,
      codeCountry,
      codePhoneNumber,
      formatPhoneNumber
    } = data;

    this.setState({
      currentValueInput: {
        flag: flag,
        countryName: countryName,
        codeCountry: codeCountry,
        codePhoneNumber: codePhoneNumber,
        placeholder: formatPhoneNumber,
        formatPhoneNumber: formatPhoneNumber,
        phoneNumber: ""
      }
    });
  };

  validateClientName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );

    this.setState({
      name: value,
      isValidName: {
        status: res.isValid,
        msg: res.message
      }
    });

    return res.isValid;
  }

  handleChangeName = e => {
    const { sessionStore } = this.props;
    sessionStore.changeClientName(e.target.value);
    this.validateClientName(e.target.value);
  };

  validateClientEmail(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "email", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );

    this.setState({
      email: value,
      isValidEmail: {
        status: res.isValid,
        msg: res.message
      }
    });

    return res.isValid;
  }

  handleChangeEmail = e => {
    const { sessionStore } = this.props;
    sessionStore.changeClientEmail(e.target.value);
    this.validateClientEmail(e.target.value);
  };

  validateClientPhoneNumber(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["customPhoneNumber", "required", { maxlength: 100 }, { minlength: 2 }],
      i18n,
      this.state.currentValueInput.formatPhoneNumber.length
    );

    this.setState({
      isValidPhoneNumber: {
        status: res.isValid,
        msg: res.message
      },
      currentValueInput: {
        ...this.state.currentValueInput,
        phoneNumber: value
      }
    });

    return res.isValid;
  }

  handleChangePhoneNumber = e => {
    const { sessionStore } = this.props;
    sessionStore.changeClientPhoneNumber(e.target.value);
    this.validateClientPhoneNumber(e.target.value);
  };

  render() {
    const {
      closeModalSendInternalNotifications,
      statusModalSendInternalNotificationsCA,
      authToken,
      sessionStore,
      candidate_id,
      job_title,
      i18n
    } = this.props;
    const {
      currentValueInput,
      isValidPhoneNumber,
      isValidName,
      isValidEmail
    } = this.state;
    const {
      clientName,
      clientEmail,
      clientPhoneNumber,
      firstName,
      phonenumber,
      lastName,
      email
    } = sessionStore;
    return (
      <div className="container-send-internal-notifications">
        <div className="modal-send-internal-notifications">
          <div className="header">
            <span>
              <Icon
                onClick={closeModalSendInternalNotifications}
                size="tiny"
                type="close"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </span>
          </div>
          <div className="main">
            <div className="title">
              <Trans>Ce profil vous intéresse ?</Trans>
            </div>
            {statusModalSendInternalNotificationsCA ? (
              <div className="content-condidate">
                <Trans>
                  Pour manifester un intérêt pour ce profil il vous faut un
                  compte client.
                </Trans>
                <br />
                <Trans>
                  Vous ne pouvez pas accéder à ces fonctionnalités avec un
                  compte Talent.
                </Trans>
              </div>
            ) : (
              <>
                <div className="content">
                  <Trans>Ce profil n'est pas publique</Trans>
                </div>
                <div>
                  <Trans>
                    Manifestez votre intérêt et nos équipes reviendrons vers
                    vous avec plus d'informations
                  </Trans>
                </div>
                {!authToken ? (
                  <FormGroup>
                    <div className="form-modal-send-internal-notifications">
                      <div className="wrapper-input">
                        <label>
                          <Trans>Name</Trans>
                        </label>
                        <Validation
                          errorMessage={isValidName.msg}
                          valid={isValidName.status}
                        >
                          <TextInputV2
                            size="small"
                            placeholder={i18n._(t`John Doe`)}
                            onChange={this.handleChangeName}
                            value={clientName}
                          />
                        </Validation>
                      </div>
                      <div className="wrapper-input">
                        <label>
                          <Trans>Email</Trans>
                        </label>
                        <Validation
                          errorMessage={isValidEmail.msg}
                          valid={isValidEmail.status}
                        >
                          <TextInputV2
                            size="small"
                            placeholder={i18n._(t`jdoe@company.com`)}
                            value={clientEmail}
                            onChange={this.handleChangeEmail}
                          />
                        </Validation>
                      </div>
                      <div className="wrapper-input">
                        <label>
                          <Trans>Phone</Trans>
                        </label>
                        <Validation
                          errorMessage={isValidPhoneNumber.msg}
                          valid={isValidPhoneNumber.status}
                        >
                          <InputPhoneNumber
                            size="small"
                            countriesData={countriesData}
                            onChange={this.handleChangePhoneNumber}
                            currentValueInput={currentValueInput}
                            selectCountrie={this.selectCountrie}
                            value={clientPhoneNumber}
                          />
                        </Validation>
                      </div>
                    </div>
                  </FormGroup>
                ) : null}
              </>
            )}
          </div>
          {!statusModalSendInternalNotificationsCA ? (
            <div className="footer">
              <ApolloConsumer>
                {apolloClient => (
                  <Mutation
                    mutation={SEND_TALENT_INTERNAL_NOTIFICATION_MUTATION}
                    variables={{
                      clientName: authToken
                        ? `${firstName} ${lastName}`
                        : clientName,
                      clientEmail: authToken ? email : clientEmail,
                      clientPhone: authToken
                        ? phonenumber + ""
                        : clientPhoneNumber,
                      candidateId: candidate_id,
                      candidateJobTitle: job_title
                    }}
                    onCompleted={this.mutationCompleted}
                    onError={errors => {
                      errors.graphQLErrors.forEach(({ message }) => {
                        if (message) {
                          this.onErrorHandler(message);
                        }
                      });
                    }}
                  >
                    {(mutation, { loading }) => (
                      <Button
                        onClick={() => {
                          return !authToken
                            ? this.isFormValid() && mutation()
                            : mutation();
                        }}
                      >
                        {loading ? (
                          <Spinner
                            type="pointed-circular"
                            color="#FFFFFF"
                            size={12}
                          />
                        ) : (
                          <div>
                            {authToken ? (
                              <Trans>Ce profil m'intéresse</Trans>
                            ) : (
                              <Trans>Envoyer</Trans>
                            )}
                          </div>
                        )}
                      </Button>
                    )}
                  </Mutation>
                )}
              </ApolloConsumer>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ModalSendInternalNotifications.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  close: PropTypes.func,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  modalIsOpen: PropTypes.bool,
  closeModalSendInternalNotifications: PropTypes.func,
  sessionStore: PropTypes.shape({
    reset: PropTypes.func,
    changeClientName: PropTypes.func,
    changeClientEmail: PropTypes.func,
    changeClientPhoneNumber: PropTypes.func,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phonenumber: PropTypes.string,
    clientName: PropTypes.string,
    clientEmail: PropTypes.string,
    clientPhoneNumber: PropTypes.string
  }).isRequired
};

ModalSendInternalNotifications.defaultProps = {
  modalIsOpen: Boolean,
  closeModalSendInternalNotifications: PropTypes.func,
  authToken: PropTypes.string
};

export default withI18n()(withRouter(ModalSendInternalNotifications));
