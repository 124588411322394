import gql from "graphql-tag";
export const GET_EXPENSE_REPORT_BY_ID = gql`
query GetExpenseReportById($id: Int!) {
    getExpenseReportByID(id: $id) {
      id
      placementId
      freelancerExpense {
        id
        email
        firstName
        lastName
      }
      title
      date
      totalAmount
      totalTVA
      status
      expensesDetails {
        id
      name
      date
      location
      amount
      tva
      type
      comment
      receipt
      receiptName
      receiptType
      }
      commentaireByFreelancer
      commentaireByFirstApproval
    }
  }  
`;