import { t } from "@lingui/macro";

const validate = (value, rules, i18n, lengthFormatPhoneNumber = null) => {
  let result = {
    isValid: true,
    message: ""
  };
  if (rules) {
    rules.forEach(ruule => {
      let rule;
      let msg;

      if (typeof ruule === "object") {
        ({ rule, msg } = ruule);
        if (!rule) {
          rule = ruule;
        }
      } else {
        rule = ruule;
      }

      if (rule === "required") {
        if (value === undefined || value === null || value.length <= 0)
          result = {
            isValid: false,
            rule,
            message: msg !== undefined ? msg : i18n._(t`Champ requis`)
          };
      }

      if (rule === "email" && value) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(value).toLowerCase()))
          result = {
            isValid: false,
            rule,
            message:
              msg !== undefined ? msg : i18n._(t`Adresse email non valide`)
          };
      }
      if (rule === "name" && value) {
        const re = /^[a-z àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'-]+$/i;
        if (!re.test(String(value).toLowerCase()))
          result = {
            isValid: false,
            rule,
            message: msg !== undefined ? msg : i18n._(t`Format non valide`)
          };
      }

      if (rule === "link" && value) {
        const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w-._~:/?#[\]@!$&'()*+,;=]+$/gm;
        if (!re.test(String(value).toLowerCase()) || !value.includes("http"))
          result = {
            isValid: false,
            rule,
            message: msg !== undefined ? msg : i18n._(t`Lien non valide`)
          };
      }
      if (rule === "number" && value) {
        if (isNaN(value))
          result = {
            isValid: false,
            rule,
            message: msg !== undefined ? msg : i18n._(t`Nombre non valide`)
          };
      }
      if (rule === "phoneNumber" && value) {
        if (isNaN(value))
          result = {
            isValid: false,
            rule,
            message:
              msg !== undefined
                ? msg
                : i18n._(t`numéro de téléphone non valide`)
          };
      }
      if (value && typeof rule === "object") {
        if (typeof rule.minlength !== "undefined" && rule.minlength > -1) {
          if (value.length < rule.minlength)
            result = {
              isValid: false,
              rule,
              message: msg !== undefined ? msg : i18n._(t`Longueur non valide`)
            };
        }
        if (typeof rule.maxlength !== "undefined" && rule.maxlength > 0) {
          if (value.length > rule.maxlength)
            result = {
              isValid: false,
              rule,
              message: msg !== undefined ? msg : i18n._(t`Longueur non valide`)
            };
        }
        if (typeof rule.similarTo !== "undefined") {
          if (value !== rule.similarTo)
            result = {
              isValid: false,
              rule,
              message:
                msg !== undefined
                  ? msg
                  : i18n._(t`Ce champ ne contient pas la même valeur`)
            };
        }
      }
      if (ruule === "customPhoneNumber") {
        const regex = new RegExp(
          `^[0-9]{${lengthFormatPhoneNumber},${lengthFormatPhoneNumber}}$`
        );
        if (!regex.test(`${value}`))
          result = {
            isValid: false,
            rule,
            message:
              msg !== undefined
                ? msg
                : i18n._(t`numéro de téléphone non valide`)
          };
      }
    });
  }
  return result;
};

export { validate as default };
