import { t } from "@lingui/macro";

export function getStatusInTheRightLanguage(status, i18n) {
    switch (status) {
        case "Draft":
            return i18n._(t`Brouillon`);
        case "Pending":
            return i18n._(t`En attente`);
        case "Approved":
            return i18n._(t`Validée`);
        case "Rejected":
            return i18n._(t`Rejetée`);
        default:
            return "";
    }
}
