import { t } from "@lingui/macro";
export const getTypeInTheRightLanguage = (type, i18n) => {
    switch (type) {
      case "Accommodation":
        return i18n._(t`Accommodation`);
      case "Business Trip":
        return i18n._(t`Business Trip`);
      case "Meal":
        return i18n._(t`Meal`);
      default:
        return "";
    }
  };
  