import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import { inject, observer } from "mobx-react";
import cookie from "react-cookies";
import { Mutation, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";
import {
  H1,
  Row,
  Col,
  Icon,
  TextInput,
  FormGroup,
  FormCard,
  Button,
  PasswordInput,
  Checkbox,
  toaster,
  Spinner,
  Validation
} from "cf-neo-ui";
import ReCAPTCHA from "react-google-recaptcha";

import Routes from "../layout/routes";
import "./styles.scss";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import ldJsonLogin from "./ldJsonLogin";
import runtimeVars from "../../configs/runTimeVars";
import classes from "./recaptchaStyles.module.scss";
import ResendValidationEmail from "./validationEmailResend";
import theme from "../../configs/theme";
import { cookieSaveToken } from "../../configs/domainConfigs";

const queryString = require("query-string");

const LOGIN_MUTATION = gql`
  mutation LogInMutation(
    $email: String!
    $password: String!
    $reCaptchaVerificationToken: String
  ) {
    login(
      email: $email
      password: $password
      reCaptchaVerificationToken: $reCaptchaVerificationToken
    ) {
      token
      account {
        name
        role
        siteLanguage
        isVerified
        step
        role
        path
      }
    }
  }
`;
const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordMutation($email: String!, $siteLanguage: String) {
    forgotpassword(email: $email, siteLanguage: $siteLanguage) {
      message
    }
  }
`;

const { AUTH_TOKEN } = runtimeVars;

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("sessionStore", "postANeedStore", "appStore")
@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.captchaRef = null;
    this.state = {
      email: "",
      emailValid: true,
      emailValidationMsg: "",
      password: "",
      passwordValid: true,
      isLogin: true,
      rememberme: false,
      reCaptchaVerificationToken: "",
      firstTrial: true,
      isReCAPTCHAValid: true
    };

    this.applyOrigin =
      queryString.parse(props.location.search).origin === "apply";
    this.validation = queryString.parse(props.location.search).validation;
  }

  componentDidMount() {
    window.scroll(0, 0);
    if (this.captchaRef) this.captchaRef.reset();
    // eslint-disable-next-line no-unused-expressions
    this.validation && this.postValidationNotif();
  }

  postValidationNotif = () => {
    const { i18n } = this.props;
    if (this.validation === "success") {
      toaster.success({
        title: i18n._(t`Félicitations!`),
        description: i18n._(t`votre compte est maintenant validé`)
      });
    } else if (this.validation === "failed") {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Votre token est expiré ou invalide`)
      });
    }
  };

  reCaptchaError = () => {};

  passwordChangeHandler = v => {
    this.setState({ password: v });
  };

  emailChangeHandler = e => {
    this.setState({ email: String(e.target.value).toLowerCase() });
  };

  forgotPasswordHandler = e => {
    e.preventDefault();
    this.setState({ isLogin: false });
  };

  LoginPasswordHandler = () => {
    this.setState({ isLogin: true });
  };

  LogInMutationCompletedHandler = async data => {
    console.log("**** data", data);
    const { login } = data;
    const { token, account } = login;
    const { sessionStore, history, postANeedStore, appStore } = this.props;
    const { changeLanguage } = appStore;
    changeLanguage(account.siteLanguage);
    const { ConfirmCode } = Routes.Hb2LayoutRoutes;
    const { Dashboard, DashboardCompany } = Routes.LpbLayoutRoutes;
    const { Offer, MyNeed } = Routes.HbLayoutRoutes;
    console.log("**** login", login);
    console.log("**** process.env.NODE_ENV", process.env.NODE_ENV);

    if (process.env.NODE_ENV === "development") {
      localStorage.setItem(AUTH_TOKEN, token);
    }

    cookie.save(AUTH_TOKEN, token, cookieSaveToken);
    sessionStore.changeAuthToken(token);
    if (login.account.isVerified === false) {
      history.push(ConfirmCode.path);
    } else {
      /* redirect management after login */
      if (sessionStore.backRef) {
        history.goBack();
      } else if (
        sessionStore.backRefCandidateSearch &&
        sessionStore.backRefCandidateSearch === "1"
      ) {
        history.goBack();
      } else if (
        sessionStore.backRefCandidateSearch &&
        sessionStore.backRefCandidateSearch === "2"
      ) {
        history.go(-2);
      } else if (login.account.role === "CANDIDATE") {
        history.push(Dashboard.path);
      } else if (postANeedStore.postANeedJustAfterSignUp === 1) {
        history.push(MyNeed.path);
      } else if (postANeedStore.postANeedJustAfterSignUp === 2) {
        history.push(Offer.path);
      } else {
        history.push(DashboardCompany.path);
      }
    }
    sessionStore.setBackRef(null);
    sessionStore.setBackRefCandidateSearch(null);
  };

  ForgotPasswordMutationCompletedHandler = data => {
    const { i18n } = this.props;
    if (data.forgotpassword) {
      toaster.success({
        title: i18n._(t`Succès`),
        description: i18n._(t`Merci de consulter votre email`)
      });
    }
  };

  remembermeChangeHandler = () => {
    const { rememberme } = this.state;
    this.setState({ rememberme: !rememberme });
  };

  loginHandler = mutation => {
    const { i18n } = this.props;
    const newState = { ...this.state };
    console.log("**** newState", newState);
    if (!newState.email) {
      newState.emailValid = false;
      newState.emailValidationMsg = i18n._(t`champ requis`);
    } else if (!EMAIL_REG.test(newState.email)) {
      newState.emailValid = false;
      newState.emailValidationMsg = i18n._(t`Entrer un email valid`);
    } else newState.emailValid = true;

    newState.passwordValid = !!newState.password;
    if (newState.emailValid && newState.passwordValid) {
      if (newState.firstTrial) mutation();
      else if (this.captchaRef && this.captchaRef.getValue()) mutation();
      else {
        newState.isReCAPTCHAValid = false;
      }
    }
    this.setState(newState);
  };

  onReCaptchaVerificationTokenChange = value => {
    this.setState({ reCaptchaVerificationToken: value });
  };

  render() {
    const { SignUp } = Routes.HbLayoutRoutes;
    const {
      email,
      emailValid,
      emailValidationMsg,
      password,
      passwordValid,
      isLogin,
      rememberme,
      firstTrial,
      reCaptchaVerificationToken,
      isReCAPTCHAValid
    } = this.state;
    const { i18n, history, appStore } = this.props;
    const { currentLanguage } = appStore;
    const meta = staticMetaTags(i18n).login;

    return (
      <ApolloConsumer>
        {client => (
          <div className="session  login">
            {metaTags(
              urls.login(),
              meta.title,
              meta.description,
              meta.openGraphImage.facebookOpenGraphImageUrl,
              meta.openGraphImage.linkedInOpenGraphImageUrl,
              meta.keywords,
              ldJsonLogin(i18n)
            )}
            <br />

            <Row>
              <Col className="center" lg={8} xl={8}>
                <br />
                <div className="center-txt bold">
                  <h1 className="title">
                    <Trans>
                      Connectez-vous à votre espace personnel Mindquest
                    </Trans>
                  </h1>
                </div>
                <br />
              </Col>
            </Row>

            <br />

            <Row>
              <Col className="center " lg={8} xl={8}>
                {console.log(
                  "*****reCaptchaVerificationToken",
                  reCaptchaVerificationToken
                )}
                <Mutation
                  mutation={LOGIN_MUTATION}
                  variables={{ email, password, reCaptchaVerificationToken }}
                  onCompleted={data => this.LogInMutationCompletedHandler(data)}
                  onError={errors => {
                    errors.graphQLErrors.forEach(({ message }) => {
                      this.setState({ firstTrial: false });
                      this.captchaRef.reset();
                      if (message) {
                        if (
                          message ===
                          'Unexpected error value: "login ou mot de passe invalide"'
                        ) {
                          toaster.error({
                            title: i18n._(t`Erreur`),
                            description: i18n._(
                              t`login ou mot de passe invalide`
                            )
                          });
                        } else if (
                          message ===
                          'Unexpected error value: "login ou mot de passe invalide"'
                        ) {
                          toaster.error({
                            title: i18n._(t`Erreur`),
                            description: i18n._(
                              t`login ou mot de passe invalide`
                            )
                          });
                        } else if (
                          message ===
                          'Unexpected error value: "INVERIFIED_ACCOUNT"'
                        ) {
                          toaster.info({
                            title: i18n._(t`Compte non vérifié`),
                            description: i18n._(
                              t`Un e-mail de validation vous a été envoyé pour vérifier votre compte.`
                            )
                          });
                        } else if (
                          message ===
                          'Unexpected error value: "INVALID RECAPTCHA"'
                        ) {
                          toaster.error({
                            title: i18n._(t`Erreur`),
                            description: i18n._(t`INVALID RECAPTCHA`)
                          });
                        } else {
                          toaster.error({
                            title: i18n._(t`Erreur`),
                            description: i18n._(t`Erreur non identifiée`)
                          });
                        }
                      }
                    });
                  }}
                >
                  {(LoginMutation, { loading }) => (
                    <FormCard className="login-card center">
                      <Row>
                        <Col
                          className="center "
                          lg={10}
                          xl={10}
                          md={10}
                          sm={10}
                          xs={5}
                        >
                          {isLogin ? (
                            <div className="hello-txt center-txt">
                              {this.applyOrigin ? (
                                <div>
                                  <Trans>
                                    Vous devez vous connecter pour pouvoir
                                    postuler
                                  </Trans>
                                  <br />
                                  <Trans>ou inscrivez-vous maintenant</Trans>
                                </div>
                              ) : (
                                <div>
                                  <h2>
                                    <Trans>Ravis de vous revoir</Trans>! <br />
                                    <Trans>
                                      Prêt à trouver une nouvelle mission ?
                                    </Trans>
                                  </h2>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="hello-txt center-txt">
                              <Trans>Mot de passe oublié</Trans> <br />
                            </div>
                          )}
                          <br />
                          <FormGroup>
                            <label className="form-label" htmlFor="login_email">
                              <Trans>Email</Trans>
                            </label>
                            <Validation
                              valid={emailValid}
                              errorMessage={emailValidationMsg}
                            >
                              <TextInput
                                value={email}
                                id="login_email"
                                type="email"
                                spellCheck="false"
                                onChange={this.emailChangeHandler}
                                onEnterPressed={() =>
                                  isLogin && this.loginHandler(LoginMutation)
                                }
                                className="form-input"
                                placeholder={i18n._(t`monemail@email.com`)}
                              />
                            </Validation>
                          </FormGroup>
                          {isLogin ? (
                            <div>
                              <FormGroup>
                                <label
                                  className="form-label"
                                  htmlFor="login_psw"
                                >
                                  <Trans>Mot de passe</Trans>
                                </label>
                                <Validation
                                  valid={passwordValid}
                                  errorMessage={i18n._(t`Champ requis`)}
                                >
                                  <PasswordInput
                                    id="login_psw"
                                    placeholder={i18n._(
                                      t`Entrer votre mot de passe`
                                    )}
                                    value={password}
                                    onChange={this.passwordChangeHandler}
                                    onEnterPressed={() =>
                                      this.loginHandler(LoginMutation)
                                    }
                                  />
                                </Validation>
                              </FormGroup>
                              <Row noGape>
                                <Col noGutter>
                                  <Checkbox
                                    name="rememberme"
                                    value="rememberme"
                                    checked={rememberme}
                                    label={i18n._(t`Se souvenir de moi`)}
                                    onClickButton={this.remembermeChangeHandler}
                                  />
                                </Col>
                                <Col noGutter>
                                  <a onClick={this.forgotPasswordHandler}>
                                    <div className="forgot-password">
                                      <Trans>Mot de passe oublié</Trans> ?
                                    </div>
                                  </a>
                                </Col>
                              </Row>
                              {!firstTrial && (
                                <Row noGap style={{ posistion: "relative" }}>
                                  <Col
                                    noGutter
                                    className={classes.recaptchaContainer}
                                  >
                                    <div className={classes.showOnXs}>
                                      <ReCAPTCHA
                                        ref={el => {
                                          this.captchaRef = el;
                                        }}
                                        size="compact"
                                        sitekey={runtimeVars.APP_CAPTCHA_KEY}
                                        onChange={
                                          this
                                            .onReCaptchaVerificationTokenChange
                                        }
                                      />
                                      {!isReCAPTCHAValid && (
                                        <small
                                          className={classes.invalidRecaptcha}
                                        >
                                          <Trans>
                                            Veuillez valider la ReCaptcha
                                          </Trans>
                                        </small>
                                      )}
                                    </div>
                                    <div className={classes.hideOnXs}>
                                      <ReCAPTCHA
                                        ref={el => {
                                          this.captchaRef = el;
                                        }}
                                        size="normal"
                                        sitekey={runtimeVars.APP_CAPTCHA_KEY}
                                        onChange={
                                          this
                                            .onReCaptchaVerificationTokenChange
                                        }
                                      />
                                      {!isReCAPTCHAValid && (
                                        <small
                                          className={classes.invalidRecaptcha}
                                        >
                                          <Trans>
                                            Veuillez valider la ReCaptcha
                                          </Trans>
                                        </small>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <Row className="login-buttons">
                                <Col lg={12} xl={12}>
                                  <Button
                                    disabled={loading}
                                    icon="chevron-right"
                                    onClick={() =>
                                      this.loginHandler(LoginMutation)
                                    }
                                  >
                                    {loading ? (
                                      <Spinner
                                        type="pointed-circular"
                                        color="#FFFFFF"
                                        size={12}
                                      />
                                    ) : (
                                      <Trans>Se connecter</Trans>
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                              <Row className="createAccount-buttons">
                                <Col lg={6} xl={6}>
                                  <Button
                                    disabled={loading}
                                    variant="tertiary"
                                    onClick={() =>
                                      history.push(
                                        `${SignUp.path}?type=consultant`
                                      )
                                    }
                                  >
                                    {loading ? (
                                      <Spinner
                                        type="pointed-circular"
                                        color="#FFFFFF"
                                        size={12}
                                      />
                                    ) : (
                                      <Trans>
                                        Créer un compte candidat (talent)
                                      </Trans>
                                    )}
                                  </Button>
                                </Col>
                                <Col lg={6} xl={6}>
                                  <Button
                                    variant="tertiary"
                                    disabled={loading}
                                    onClick={() =>
                                      history.push("/signup?type=enterprise")
                                    }
                                  >
                                    {loading ? (
                                      <Spinner
                                        type="pointed-circular"
                                        color="#FFFFFF"
                                        size={12}
                                      />
                                    ) : (
                                      <Trans>
                                        Créer un compte entreprise (recruteur)
                                      </Trans>
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div>
                              <Row className="login-buttons">
                                <Col lg={6} xl={6}>
                                  <Button
                                    variant="secondary"
                                    className="join-club"
                                    icon="chevron-left"
                                    iconPosition="left"
                                    onClick={this.LoginPasswordHandler}
                                  >
                                    <Trans>Se connecter</Trans>
                                  </Button>
                                </Col>
                                <Col>
                                  <Mutation
                                    mutation={FORGOT_PASSWORD_MUTATION}
                                    variables={{
                                      email,
                                      siteLanguage: currentLanguage
                                    }}
                                    onCompleted={dataM =>
                                      this.ForgotPasswordMutationCompletedHandler(
                                        dataM
                                      )
                                    }
                                    onError={errors => {
                                      errors.graphQLErrors.forEach(
                                        ({ message }) => {
                                          if (
                                            message ===
                                            'Unexpected error value: "Aucun compte ne correspond à cet email"'
                                          ) {
                                            toaster.error({
                                              title: i18n._(t`Erreur`),
                                              description: i18n._(
                                                t`Aucun compte ne correspond à cet email`
                                              )
                                            });
                                          } else {
                                            toaster.error({
                                              title: i18n._(t`Erreur`),
                                              description: message
                                            });
                                          }
                                        }
                                      );
                                    }}
                                  >
                                    {mutation => (
                                      <Button
                                        icon="chevron-right"
                                        onClick={() => {
                                          return mutation();
                                        }}
                                      >
                                        <Trans>
                                          Réinitialiser votre mot de passe
                                        </Trans>
                                      </Button>
                                    )}
                                  </Mutation>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </FormCard>
                  )}
                </Mutation>
              </Col>
            </Row>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

Login.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeAuthToken: PropTypes.func,
    backRef: PropTypes.string,
    backRefCandidateSearch: PropTypes.string,
    setBackRef: PropTypes.func,
    setBackRefCandidateSearch: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    changeLanguage: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    postANeedJustAfterSignUp: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number
    ])
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Login));
