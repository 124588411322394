import React, { Component } from "react";
import * as PropTypes from "prop-types";
import cookie from "react-cookies";
import { Modal } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import ChoicesWidget from "./ChoicesWidget/ChoicesWidget";
import runtimeVars from "../../configs/runTimeVars";
const { DOMAIN, AUTH_TOKEN } = runtimeVars;

class CookiesConsent extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  actionCookieHandler = action => {
    let plusTime = action == "consent" ? 31104000000 : 604800000;
    cookie.save("actionCookie", Date.now(), {
      path: "/",
      domain: DOMAIN,
      expires: new Date(Date.now() + plusTime)
    });
  };

  consentHandler = () => {
    cookie.save("cookies-consent", Date.now(), {
      path: "/",
      domain: DOMAIN,
      expires: new Date(Date.now() + 31104000000)
    });
    this.actionCookieHandler("consent");
  };

  denyHandler = () => {
    this.deleteAllCookies();
    this.actionCookieHandler("deny");
    cookie.save("cookies-deny", Date.now(), {
      path: "/",
      domain: DOMAIN,
      expires: new Date(Date.now() + 604800000)
    });
  };

  deleteAllCookies = () => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const oneCookie = cookies[i];
      let name = oneCookie.split("=")[0];
      if (name != AUTH_TOKEN)
        document.cookie =
          name +
          "=; Path=/; Domain=" +
          DOMAIN +
          "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  };

  choiceHandler = () => {
    this.setState({ isVisible: true });
  };

  displayManager = () => {
    const { i18n } = this.props;
    const { isVisible } = this.state;

    if (!cookie.load("actionCookie")) {
      return (
        <div>
          <Modal
            // style={{ overflow: "auto", height: "100%"}}
            centred
            isVisible={isVisible}
            withCloseButton
            position="fixed"
            onClose={() => this.setState({ isVisible: false })}
          >
            <ChoicesWidget />
          </Modal>
        </div>
      );
    }
    return null;
  };

  render() {
    return this.displayManager();
  }
}

CookiesConsent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(CookiesConsent);
