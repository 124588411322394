export const getIconColor = status => {
    switch (status) {
      case "Draft":
        return "#979797";
      case "Approved":
        return "#1CC02D";
      case "Pending":
        return "#B99D39";
      case "Rejected":
        return "#C40B24";
      default:
        return "#000000";
    }
  };
  