import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { useMutation } from "react-apollo";
import { CREATE_EXPENSE } from "../filtered-expenses/mutations";
import {
  FormGroup,
  Select,
  Spinner,
  TextInput,
  Validation,
  toaster
} from "cf-neo-ui";
import validate from "../../../utils/validators";
import "./addExpenseReportForm.css";

const AddExpenseReportForm = ({ i18n, history, placementOptions }) => {
  const [title, setTitle] = useState("");
  const [placementId, setPlacementId] = useState();
  const [date, setDate] = useState("");
  const [isPlacementIdValid, setIsPlacementIdValid] = useState(true);
  const [
    placementIdValidationMessage,
    setPlacementIdValidationMessage
  ] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [createExpense] = useMutation(CREATE_EXPENSE);

  const validatePlacementId = value => {
    const res = validate(value, ["required"], i18n);
    setIsPlacementIdValid(res.isValid);
    setPlacementIdValidationMessage(res.message);
    return res.isValid;
  };

  const handleCreateExpense = async () => {
    if (!validatePlacementId(placementId)) return;

    setLoadingSubmit(true);

    try {
      const { data } = await createExpense({
        variables: {
          title,
          placementId,
          date
        }
      });
      const newExpense = data.create;
      history.push(`/dashboard/expenses/${newExpense.id}`);
    } catch (error) {
      toaster.error({
        title: i18n._(t`Erreur lors de l'ajout`),
        description: i18n._(
          t`Une erreur est survenue lors de l'ajout de la note de frais. Veuillez réessayer plus tard.`
        )
      });
    } finally {
      setLoadingSubmit(false);
    }
  };
  return (
    <FormGroup>
      <div className="form">
        <div className="ajouter">
          <Trans>Ajouter une note de frais</Trans>
        </div>{" "}
        <br />
        <TextInput
          style={{ height: "50px" }}
          placeholder={i18n._(t`Objet de la note frais (soyez explicites)`)}
          size="normal"
          className="TextInput"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <br />
        <div className="containerInput">
          <div className="inputWrapper">
            <TextInput
              style={{
                height: "50px",
                fontWeight: "normal",
                color: date ? "black" : "#c4c8cc"
              }}
              type="date"
              className="dateInput"
              value={date}
              onChange={e => setDate(e.target.value)}
            />
            <img
              src="/assets/svg/calendar.svg"
              alt="calendar"
              className="inputIcon"
            />
          </div>
        </div>
        <br />
        <Validation
          errorMessage={placementIdValidationMessage}
          valid={isPlacementIdValid}
        >
          <Select
            className="select"
            id="selectedItem"
            placeholder={i18n._(t`Contrat ID`)}
            options={placementOptions}
            value={placementId}
            onSelect={option => {
              const newValue = option.value;
              setPlacementId(newValue);
              validatePlacementId(newValue);
            }}
          />
        </Validation>
        <br />
        <div>
          <button
            style={{ width: "155px" }}
            className="addButton"
            onClick={handleCreateExpense}
            disabled={loadingSubmit}
          >
            <img src={"/assets/svg/add.svg"} alt="add" />
            <span className="addTxt">
              <Trans>Ajouter</Trans>
            </span>
          </button>
        </div>
      </div>
    </FormGroup>
  );
};

AddExpenseReportForm.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired
  }).isRequired,
  email: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  placementOptions: PropTypes
};

export default AddExpenseReportForm;
