import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import Header from "./Header";
import { t } from "@lingui/macro";
import FilteredExpenses from "./filtered-expenses/FilteredExpenses";

@inject("appStore", "sessionStore")
@observer
class Expenses extends Component {
  render() {
    const { sessionStore, i18n, history } = this.props;
    const role = sessionStore.account.role;

    return (
      <div>
        <Header
          headerTitle={
            role === "CLIENT"
              ? i18n._(t`Vous avez un besoin CDI ou Freelance`)
              : i18n._(t`Générez un CV structuré`)
          }
          headerSubtitle={
            role === "CLIENT"
              ? i18n._(
                  t`Nos équipes vous accompagnent sur tous vos besoins IT, Digital et Finance`
                )
              : i18n._(
                  t`Completez votre profil pour générer un CV téléchargeable et partageable en ligne`
                )
          }
          buttonText={
            role === "CLIENT"
              ? i18n._(t`Poster un besoin`)
              : i18n._(t`Completer mon profil`)
          }
          buttonRedirect={
            role === "CLIENT"
              ? "/dashboard/company/offers"
              : "/dashboard/my-profile"
          }
          history={history}
        />
        <br />
        <FilteredExpenses i18n={i18n} history={history} sessionStore={sessionStore}/>
      </div>
    );
  }
}

Expenses.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default withI18n()(withRouter(Expenses));
