import React from "react";
import { Helmet } from "react-helmet-async";
import CFGlobals from "../configs/club-freelance-globals";
import runtimeVars from "../configs/runTimeVars";

const WEBSITE = CFGlobals.name;
const ADDRESS = runtimeVars.FRONTEND_BASE_URL;
const noIndex = runtimeVars.noIndex;
const noCache = runtimeVars.noCache;
const baseUrl = runtimeVars.FRONTEND_BASE_URL;
const frenchUrl = runtimeVars.FRONTEND_BASE_FR_URL;
var site_name = WEBSITE;
const defaults = {
  title: WEBSITE,
  description:
    "Trouvez les plus belles offres IT et les meilleurs consultants Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle... Rejoignez Club Freelance, la communauté des talents et recruteurs IT.",
  defaultOpenGraph: {
    defaultFacebookOpenGraphImageUrl: `${ADDRESS}/mq.webp`,
    defaultLinkedInOpenGraphImageUrl: `${ADDRESS}/mq.webp`
  },
  keywords: "conusuling, missions, club freelance"
};

const metaTags = (
  url,
  title,
  description,
  facebookOpenGraphImageUrl,
  linkedInOpenGraphImageUrl,
  keywords,
  ldJson,
  language,
  indexable = true
) => {
  if (defaults.title === "Mindquest") {
    defaults.description =
      "Votre partenaire pour donner de l’élan à vos projets IT et à votre carrière dans la tech. CDI ou contrat : recrutez les meilleurs talents IT pour accélérer sur vos projets. Un account manager dédié et aucun prépaiement.";
    defaults.keywords = "conusuling, missions, Mindquest";
  }
  if (typeof window != "undefined" && !language)
    language = localStorage.getItem("language");

  if (typeof window != "undefined")
    site_name =
      window.location.pathname === "/talent-finance" ||
      window.location.pathname === "/service-entreprise-finance"
        ? "Mindquest Finance"
        : site_name;

  const parsed_url = url ? new URL(url) : null;
  return (
    <Helmet>
      <link rel="canonical" href={url} />

      <title>{title || defaults.title}</title>
      {noIndex || !indexable ? <meta name="robots" content="none" /> : ""}
      <meta name="title" content={title || defaults.title} />
      {description ? <meta name="description" content={description} /> : ""}
      {noCache ? <meta http-equiv="cache-control" content="no-cache" /> : ""}
      {noCache ? <meta http-equiv="expires" content="0" /> : ""}

      <meta name="keywords" content={keywords || defaults.keywords} />
      {<meta property="og:url" content={url} />}

      <meta name="copyright" content={WEBSITE} />
      <meta name="identifier-URL" content={ADDRESS} />
      {parsed_url ? (
        <link
          id="site-language"
          rel="alternate"
          href={
            language == "fr"
              ? frenchUrl + parsed_url.pathname
              : baseUrl + parsed_url.pathname
          }
          hreflang={language == "fr" ? "fr" : "en"}
        />
      ) : (
        ""
      )}
      {/* openGraph Meta Tags */}
      <meta property="og:site_name" content={site_name} />
      <meta property="og:type" content="website" />
      <meta
        name="title"
        property="og:title"
        content={title || defaults.title}
      />
      <meta
        name="description"
        property="og:description"
        content={description || defaults.description}
      />

      {/* 600x315 Image for Facebook */}
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="314" />
      {/*<meta property="og:url" content={facebookOpenGraphImageUrl} />*/}
      <meta
        name="image"
        property="og:image"
        content={
          facebookOpenGraphImageUrl ||
          defaults.defaultOpenGraph.defaultFacebookOpenGraphImageUrl
        }
      />

      {/* 180x110 Image for Linkedin */}
      <meta property="og:image:width" content="180" />
      <meta property="og:image:height" content="110" />
      {/*<meta property="og:url" content={linkedInOpenGraphImageUrl} />*/}
      <meta
        property="og:image"
        content={
          linkedInOpenGraphImageUrl ||
          defaults.defaultOpenGraph.defaultLinkedInOpenGraphImageUrl
        }
      />

      {/* Facebook default dimensions used for Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={WEBSITE} />
      {/*<meta name="twitter:url" content={facebookOpenGraphImageUrl} />*/}
      <meta name="twitter:title" content={title || defaults.title} />
      <meta
        name="twitter:description"
        content={description || defaults.description}
      />
      <meta name="twitter:image:alt" content={title || defaults.title} />
      <meta
        name="twitter:image"
        content={
          facebookOpenGraphImageUrl ||
          defaults.defaultOpenGraph.defaultFacebookOpenGraphImageUrl
        }
      />

      {/* google data type */}
      {ldJson &&
        ldJson.map(e => (
          <script type="application/ld+json">{JSON.stringify(e)}</script>
        ))}
    </Helmet>
  );
};

export default metaTags;
