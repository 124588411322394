import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { MenuDashBoard, ItemMenu, Icon } from "cf-neo-ui";
import SiderMenu from "./SiderMenu";
import SiderMenuSupport from "./SiderMenuSupport";
import SiderMenuItem from "./SiderMenuItem";
import Routes from "../../routes";
import classes from "../styles.scss";
import { LOGOUT_MUTATION } from "../../hbfLayout/mutation";
import SiderMenuItemSupport from "./SiderMenuItemSupport";
import { Divider } from "cf-neo-ui";
@inject(
  "sessionStore",
  "referenceTask",
  "postANeedStore",
  "candidatesSearchStore"
)
@observer
class SiderCompany extends Component {
  constructor(props) {
    super(props);
    this.myCompanyHandler = this.myCompanyHandler.bind(this);
    this.myInformationHandler = this.myInformationHandler.bind(this);
  }

  logoutClickHandler = () => {
    const {
      sessionStore,
      history,
      postANeedStore,
      candidatesSearchStore,
      client,
      apolloClient
    } = this.props;
    const { Home } = Routes.HbfLayoutRoutes;
    postANeedStore.reset();
    apolloClient.mutate({
      mutation: LOGOUT_MUTATION,
      variables: { token: sessionStore.authToken }
    });
    sessionStore.logout();
    candidatesSearchStore.clearSearch();
    client.cache.reset();
    history.push(Home.path);
  };

  myCompanyHandler = () => {
    const { MyCompany } = Routes.LpbLayoutRoutes;
    const { history, referenceTask } = this.props;
    const { setSideMenu } = referenceTask;
    setSideMenu();
    history.push(MyCompany.path);
  };

  myParamsHandler = () => {
    const { MyParamsCompany } = Routes.LpbLayoutRoutes;
    const { history, referenceTask } = this.props;
    const { setSideMenu } = referenceTask;
    setSideMenu();
    history.push(MyParamsCompany.path);
  };

  myInformationHandler = () => {
    const { MyInformationCompany } = Routes.LpbLayoutRoutes;
    const { history, referenceTask } = this.props;
    const { setSideMenu } = referenceTask;
    setSideMenu();
    history.push(MyInformationCompany.path);
  };

  render() {
    const { history, location, sessionStore, i18n, referenceTask } = this.props;
    const { pictureUrl, profilePhoto } = sessionStore;
    const { LpbLayoutRoutes, HbfLayoutRoutes, HbLayoutRoutes } = Routes;
    const { isSideMenuOpen, setSideMenu } = referenceTask;
    return (
      <aside className={`lpb-layout-sider ${isSideMenuOpen ? "active" : ""}`}>
        <div className="sider-logo">
          <Link to={HbfLayoutRoutes.Home.path}>
            <picture>
              <source
                type="image/webp"
                srcSet="/assets/images/logo_mindquest_dash.webp"
              />
              <img
                src="/assets/images/logo_mindquest_dash.webp"
                alt="mindquest"
              />
            </picture>
          </Link>
          <p className="between_logos">&</p>
          <Link to={HbfLayoutRoutes.Home.path}>
            <picture>
              <source
                type="image/jpg"
                srcSet="/assets/images/clubfreelance.jpg"
              />
              <img src="/assets/images/clubfreelance.jpg" alt="mindquest" />
            </picture>
          </Link>
        </div>
        <div className="sider-close">
          <Icon type="close" width={12} height={11} onClick={setSideMenu} />
        </div>
        <SiderMenu>
          <SiderMenuItem
            active={location.pathname === LpbLayoutRoutes.DashboardCompany.path}
            iconType="speed-meter"
            title={i18n._(t`Dashboard`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.DashboardCompany.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname === LpbLayoutRoutes.Offers.path}
            iconType="flag"
            title={i18n._(t`Offres`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Offers.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname === LpbLayoutRoutes.Talents.path}
            iconType="talent"
            title={i18n._(t`Talents`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Talents.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname.split("/")[2] === "timesheets"}
            iconType="clock"
            title={i18n._(t`Timesheets`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Timesheets.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname === "documentsContracts"}
            iconType="document"
            title={i18n._(t`Documents`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.DocumentsContracts.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname === "expenses"}
            // TODO: ADD EURO ICON
            iconType="eye"
            title={i18n._(t`Notes de frais`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Expenses.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname === LpbLayoutRoutes.Stats.path}
            iconType="statistiques"
            title={i18n._(t`Statistiques`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Stats.path);
              setSideMenu();
            }}
          />
        </SiderMenu>
        <div style={{ height: "15px", marginTop: "-20px" }}>
          <Divider
            orientation="horizontal"
            color="#dadee3"
            type="solid"
            thickness="2px"
          />
        </div>
        <SiderMenuSupport>
          <SiderMenuItemSupport
            active={location.pathname === LpbLayoutRoutes.Stats.path}
            iconType="guide"
            title={i18n._(t`Guide`)}
            onClick={() => {
              window.open("https://mindquest.eb-help.com/help", "_blank");
            }}
          />
          <SiderMenuItemSupport
            active={location.pathname === HbLayoutRoutes.contact.path}
            iconType="contact"
            title={i18n._(t`Contactez nous`)}
            onClick={() => {
              window.open(
                window.location.origin + HbLayoutRoutes.contact.path,
                "_blank"
              );
              setSideMenu();
            }}
          />
        </SiderMenuSupport>

        <div className="avatar-wrapper">
          <div className="avatar">
            <MenuDashBoard
              userName="User Name"
              percentage={100}
              image={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
            >
              <ItemMenu
                label={i18n._(t`Mes informations`)}
                value={1}
                onClick={this.myInformationHandler}
              />
              <ItemMenu
                label={i18n._(t`Mon entreprise`)}
                value={1}
                onClick={this.myCompanyHandler}
              />
              <ItemMenu
                label={i18n._(t`Mes paramètres`)}
                value={1}
                onClick={this.myParamsHandler}
              />
              <ItemMenu
                label={i18n._(t`Se déconnecter`)}
                value={2}
                onClick={this.logoutClickHandler}
              />
            </MenuDashBoard>
          </div>
          <div className="user-name">
            {sessionStore.client.firstName} {sessionStore.client.lastName}
          </div>
        </div>
      </aside>
    );
  }
}

SiderCompany.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    reset: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    logout: PropTypes.func,
    pictureUrl: PropTypes.string,
    profilePhoto: PropTypes.string,
    client: PropTypes.object,
    authToken: PropTypes.string
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  referenceTask: PropTypes.shape({
    isSideMenuOpen: PropTypes.bool,
    setSideMenu: PropTypes.func
  }).isRequired,
  client: PropTypes.shape({
    cache: PropTypes.object
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired
};

export default withApollo(withI18n()(withRouter(SiderCompany)));
