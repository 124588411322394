import React from "react";
import classes from "./styles.module.scss";
import { Button } from "cf-neo-ui";
import { Trans } from "@lingui/macro";

const Header = ({ headerTitle, headerSubtitle, buttonText, buttonRedirect, history }) => {
  return (
    <div className={classes.header}>
      <div className={classes.headerText}>
        <div className={classes.generez}>{headerTitle}</div>
        <span className={classes.completez}>{headerSubtitle}</span>
      </div>
      <Button
        style={{ fontSize: "13px" }}
        onClick={() => history.push(buttonRedirect)}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default Header;
