export function formatDate(date, format = "fr") {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Les mois commencent à partir de 0
    const year = dateObject.getFullYear() % 100; // Récupération des deux derniers chiffres de l'année

    // Formatage de la date en français
    const formattedDateFR = `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""
        }${month}/${year}`;

    // Formatage de la date en anglais
    const formattedDateEN = `${month < 10 ? "0" : ""
        }${month}/${day}/${year}`;

    // Sélection du format en fonction du langue
    const formattedDate =
        format === "fr" ? formattedDateFR : formattedDateEN;

    return formattedDate;
}




// formatDate = dateString => {
//     const { i18n } = this.props;
//     const date = new Date(dateString);
//     return date.toLocaleDateString(i18n._(t`fr-FR`)); // Format : dd/mm/yyyy en Fr et mm/dd/yyyy en EN
//   };
