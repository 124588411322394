import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { H2, H5, Spinner } from "cf-neo-ui";
import { Trans, plural } from "@lingui/macro";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import Articles from "./blog-cards-display/BlogCardsDisplay";
import classes from "./blogcategory.module.scss";
import { categoriesRefEn, categoriesRefFr, latestPostsQuery } from "./query";
import staticPagesMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import PlusButton from "./plus-button/PlusButton";
import ldJson from "./ldJson";
import SubMenu from "./sub-menu/SubMenu";
const queryString = require("query-string");
import { withRouter } from "react-router-dom";
import BlogCategories from "../../utils/BlogCategories";
import NotFoundPage from "../../components/error-pages/NotFoundPage";

@inject("appStore")
@observer
class BlogCategory extends Component {
  constructor(props) {
    super(props);
    this.latestPostsPage = 1;
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  showMore = (fetchMore, currentCategory) => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    this.latestPostsPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestPostsPage,
        perPage: 9,
        categoryIds:
          currentLanguage === "fr"
            ? categoriesRefFr[currentCategory.name]
            : categoriesRefEn[currentCategory.name],
        language: currentLanguage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  loadingDisplay() {
    return (
      <Spinner
        className={classes.spinner}
        type="pointed-circular"
        color="red"
        size={120}
      />
    );
  }

  render() {
    const { i18n, appStore, match, location } = this.props;
    const { category, subCategory, ssubCategory } = match.params;
    const { currentLanguage } = appStore;

    var url = "";
    if (typeof window !== "undefined") {
      url = window.location.href;
    }
    let currentCategory = {
      id: "",
      title: "",
      name: "",
      msg: "",
      url: "",
      subCategories: []
    };
    const blogCategories = BlogCategories(i18n);
    const blog = Object.values(blogCategories);
    if (category && subCategory && ssubCategory) {
      blog.map(e => {
        if (e.url.includes(category) && e.lang.includes(currentLanguage)) {
          if (e && e.subCategories) {
            e.subCategories.map(subcategory => {
              if (
                subcategory.url.includes(subCategory) &&
                subcategory.lang.includes(currentLanguage)
              ) {
                if (subcategory && subcategory.subCategories) {
                  subcategory.subCategories.map(ssub => {
                    if (
                      ssub.url.includes(ssubCategory) &&
                      ssub.lang.includes(currentLanguage)
                    ) {
                      currentCategory = {
                        id: ssub.id,
                        title: ssub.title,
                        url: ssub.url,
                        msg: ssub.msg,
                        name: ssub.name,
                        subCategories: e.subCategories
                      };
                    }
                  });
                }
              }
            });
          }
        }
      });
    } else if (category && subCategory) {
      blog.map(e => {
        if (e.url.includes(category) && e.lang.includes(currentLanguage)) {
          if (e && e.subCategories) {
            e.subCategories.map(s => {
              if (s.lang.includes(currentLanguage)) {
                currentCategory.subCategories.push(s);
                if (s.url.includes(subCategory)) {
                  currentCategory.id = s.id;
                  currentCategory.title = s.title;
                  currentCategory.url = s.url;
                  currentCategory.msg = s.msg;
                  currentCategory.name = s.name;
                }
              }
            });
          }
        }
      });
    } else if (category) {
      blog.map(e => {
        if (e.url.includes(category) && e.lang.includes(currentLanguage)) {
          currentCategory.id = e.id;
          currentCategory.title = e.title;
          currentCategory.msg = e.msg;
          currentCategory.url = e.url;
          currentCategory.name = e.name;
          if (e && e.subCategories) {
            e.subCategories.map(s => {
              if (s.lang.includes(currentLanguage)) {
                currentCategory.subCategories.push(s);
              }
            });
          }
        }
      });
    }

    const meta =
      currentCategory && currentCategory.id
        ? staticPagesMetaTags(i18n).blog[`${currentCategory.name}`]
        : staticPagesMetaTags(i18n).notFound;
    const indexable = currentCategory && currentCategory.id ? true : false;
    return (
      <div>
        {metaTags(
          url,
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson(i18n),
          currentLanguage,
          indexable
        )}
        {currentCategory && currentCategory.id ? (
          <div>
            <div className={classes.limitedBody}>
              <section>
                {currentCategory.id && (
                  <SubMenu
                    dataToSend={{
                      title: currentCategory.title,
                      msg: currentCategory.msg,
                      subCategories: currentCategory.subCategories,
                      url: currentCategory.url ? currentCategory.url : ""
                    }}
                  />
                )}
              </section>
            </div>

            {currentCategory && currentCategory.id ? (
              <Query
                query={latestPostsQuery}
                variables={{
                  page: 1,
                  perPage: 9,
                  categoryIds:
                    currentLanguage === "fr"
                      ? currentCategory.id.fr.toString()
                      : currentCategory.id.en.toString(),
                  language: currentLanguage
                }}
                fetchPolicy="cache-and-network"
              >
                {({ loading, error, data, fetchMore, networkStatus }) => {
                  if (error)
                    return (
                      <div className="error_container">
                        <span>
                          {` Oups! il semblerait que vos données aient du mal à se
                 synchroniser. Veuillez rafraichir la page dans un moment`}
                        </span>
                        <img
                          style={{
                            margin: 20
                          }}
                          src={"/assets/svg/download.svg"}
                          alt="error"
                        />
                      </div>
                    );
                  if (networkStatus === 1) return this.loadingDisplay();
                  return (
                    <div className={classes.limitedBodys}>
                      <section>
                        <div className={classes.text}>
                          <H5 className={classes.articleHeading}>
                            <span>
                              <Trans>
                                {i18n._(
                                  plural({
                                    value:
                                      data.posts && data.posts.length > 0
                                        ? data.posts[0].total
                                        : 0,
                                    one: "# Article à lire",
                                    other: "# Articles à lire"
                                  })
                                )}
                              </Trans>
                            </span>
                          </H5>
                        </div>
                      </section>
                      <section>
                        {data && data.posts && data.posts.length > 0 ? (
                          <Articles articles={data.posts} />
                        ) : (
                          <div className="error_container">
                            <span>{` Pas de contenu pour le moment !`}</span>
                            <img
                              style={{
                                margin: 20
                              }}
                              src={"/assets/svg/download.svg"}
                              alt="error"
                            />
                          </div>
                        )}

                        {networkStatus === 3 && (
                          <Spinner
                            className={classes.spinner}
                            type="pointed-circular"
                            color="#c40b24"
                            size={120}
                          />
                        )}
                      </section>
                      <section style={{ marginBottom: "20px" }}>
                        {!loading &&
                          data.posts.length > 0 &&
                          data.posts[0] &&
                          this.latestPostsPage < data.posts[0].totalPages && (
                            <PlusButton
                              onClick={() =>
                                this.showMore(fetchMore, currentCategory)
                              }
                            >
                              <Trans>More articles</Trans>
                            </PlusButton>
                          )}
                      </section>
                    </div>
                  );
                }}
              </Query>
            ) : (
              <NotFoundPage message={"Page not exist"} />
            )}
          </div>
        ) : (
          <NotFoundPage message={"Page not exist"} />
        )}
      </div>
    );
  }
}

BlogCategory.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string
    })
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(BlogCategory));
