import React from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { Button, toaster } from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { Mutation } from "react-apollo";
import { SEND_EXPENSE } from "../filtered-expenses/mutations";
import { withI18n } from "@lingui/react";

import "./ExpenseReportCard.css";
import { formatDate } from "../helpers/formatDate";
import { getStatusInTheRightLanguage } from "../helpers/getStatusInTheRightLanguage";
import { getIconColor } from "../helpers/getIconColor";

const ExpenseReportCard = ({
  id,
  placementId,
  title,
  date,
  totalAmount,
  totalTVA,
  status,
  freelancerExpense,
  role,
  email,
  history,
  i18n
}) => {
  const iconColor = getIconColor(status);

  const handleSendExpense = async (sendExpenseMutation) => {
    try {
      await sendExpenseMutation({
        variables: { id, commentaireByFreelancer: "" },
      });
      history.push(`/dashboard/expenses/${id}`);
    } catch (error) {
      toaster.error({
        title: i18n._(t`Erreur lors de l'envoi`),
        description: i18n._(t`Erreur lors de l'envoi de note de frais, veuillez réessayer plus tard.`)
      });
    }
  };

  const formattedDate = date ? formatDate(date, i18n._(t`fr`)) : "";

  return (
    <div className="single-expense">
      <div className="horizontal-line"></div>
      {role === "CLIENT" ? (
        <>
          <span className="nameFreelancer">
            {freelancerExpense.firstName} {freelancerExpense.lastName}
          </span>
          <span className="expense-title-client">{title}</span>
        </>
      ) : (
        <span className="expense-title">{title}</span>
      )}
      <span className="expense-status">
        {getStatusInTheRightLanguage(status, i18n)}
      </span>
      <i className="fa fa-circle expense-icon" style={{ color: iconColor }}></i>
      <span className="expense-date">{formattedDate}</span>
      {role === "CLIENT" && (
        <>
          <span className="expense-contrat-id">#{placementId}</span>
        </>
      )}
      <span className="tva">TVA {totalTVA}€</span>
      <span className="expense-amount">{totalAmount}€</span>
      {role === "CANDIDATE" && (
        <>
          {status === "Pending" || status === "Approved" ? (
            <Button
              className="view-btn"
              variant="secondary"
              onClick={() => history.push(`/dashboard/expenses/${id}`)}
            >
              <Trans>Consulter</Trans>
            </Button>
          ) : (
            <>
              <Mutation
                mutation={SEND_EXPENSE}
              >
                {sendExpenseMutation => (
                  <Button
                    className="send-btn"
                    onClick={() => handleSendExpense(sendExpenseMutation)}
                  >
                    <Trans>Envoyer</Trans>
                  </Button>
                )}
              </Mutation>
              <Button
                variant="secondary"
                className="edit"
                onClick={() => history.push(`/dashboard/expenses/${id}`)}
              >
                <Trans>Editer</Trans>
              </Button>
            </>
          )}
        </>
      )}
      {role === "CLIENT" && (
        <>
          <Button
            variant="secondary"
            className="view-btn"
            onClick={() => history.push(`/dashboard/expenses/${id}`)}
          >
            <Trans>Voir le détail</Trans>
          </Button>
        </>
      )}
    </div>
  );
};

ExpenseReportCard.propTypes = {
  id: PropTypes.number,
  placementId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  totalAmount: PropTypes.number.isRequired,
  totalTVA: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  freelancerExpense: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(ExpenseReportCard));
