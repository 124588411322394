import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Header from "../Header";
import classes from "./expenseReport.module.scss";
import { GET_EXPENSE_REPORT_BY_ID } from "./queries";
import { Mutation, Query } from "react-apollo";
import ExpenseCard from "../expense-card/ExpenseCard";
import { inject, observer } from "mobx-react/index";
import { Button, Icon, Spinner, toaster } from "cf-neo-ui";
import { ACCEPT_EXPENSE, REJECT_EXPENSE } from "./mutations";
import { SEND_EXPENSE } from "../filtered-expenses/mutations";
import { formatDate } from "../helpers/formatDate";
import { getStatusInTheRightLanguage } from "../helpers/getStatusInTheRightLanguage";
import { getIconColor } from "./../helpers/getIconColor";
import AddSingleExpenseForm from "../add-expense-form/AddSingleExpenseForm";

@inject("appStore", "sessionStore")
@observer
class ExpenseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentaireByFreelancer: "",
      showExpenseForm: false,
      selectedExpense: null
    };
  }
  handleExpenseSubmitted = async () => {
    // Réinitialisation de l'expense après la mutation réussie
    this.setState({ selectedExpense: null, showExpenseForm: false });

  };
  handleEditClick = expense => {
    this.setState({ selectedExpense: expense, showExpenseForm: true });
  };

  handleCloseFormClick = () => {
    this.setState({ selectedExpense: null, showExpenseForm: false });
  };
  handleAddExpenseClick = async () => {
    await this.handleExpenseSubmitted();
    this.setState({ showExpenseForm: true });
  };
  handlecommentaireByFreelancerChange = event => {
    this.setState({ commentaireByFreelancer: event.target.value });
  };
  handleSendExpense = async (sendExpenseMutation) => {
    const { match, i18n} = this.props;
    try {
      await sendExpenseMutation({
        variables: {
          //URL params are of type string.
          id: Number(match.params.id),
          commentaireByFreelancer: this.state.commentaireByFreelancer
        },
        refetchQueries: [
          {
            query: GET_EXPENSE_REPORT_BY_ID,
            variables: { id: Number(match.params.id) },
          },
        ],
      });
    } catch (error) {
      toaster.error({
        title: i18n._(t`Erreur lors de l'envoi`),
        description: i18n._(t`Erreur lors de l'envoi de note de frais, veuillez réessayer plus tard.`)
      });
    }
  };
  handleAcceptExpense = async (acceptExpenseMutation) => {
    const { match, i18n} = this.props;
    try {
      await acceptExpenseMutation({
        variables: {
          id: Number(match.params.id),
          commentaireByFirstApproval: this.state.commentaireByFreelancer
        },
        refetchQueries: [
          {
            query: GET_EXPENSE_REPORT_BY_ID,
            variables: { id: Number(match.params.id) },
          }
        ],
      });
    } catch (error) {
      toaster.error({
        title: i18n._(t`Erreur lors de l'acceptation`),
        description: i18n._(t`Erreur lors de le l'acceptation de note de frais, veuillez réessayer plus tard.`)
      });
    }
  };
  handleRejectExpense = async (rejectExpenseMutation) => {
    const { match, i18n} = this.props;
    try {
      await rejectExpenseMutation({
        variables: {
          id: Number(match.params.id),
          commentaireByFirstApproval: this.state.commentaireByFreelancer
        },
        refetchQueries: [
          {
            query: GET_EXPENSE_REPORT_BY_ID,
            variables: { id: Number(match.params.id) },
          }
        ],
      });
    } catch (error) {
      toaster.error({
        title: i18n._(t`Erreur lors du refus`),
        description: i18n._(t`Erreur lors du refus de note de frais, veuillez réessayer plus tard.`)
      });
    }
  };
  render() {
    const { match, i18n, sessionStore, history } = this.props;
    const role = sessionStore.account.role;
    const { showExpenseForm, selectedExpense } = this.state;
    return (
      <div>
        <Header
          headerTitle={
            role === "CLIENT"
              ? i18n._(t`Vous avez un besoin CDI ou Freelance`)
              : i18n._(t`Générez un CV structuré`)
          }
          headerSubtitle={
            role === "CLIENT"
              ? i18n._(
                t`Nos équipes vous accompagnent sur tous vos besoins IT, Digital et Finance`
              )
              : i18n._(
                t`Completez votre profil pour générer un CV téléchargeable et partageable en ligne`
              )
          }
          buttonText={
            role === "CLIENT"
              ? i18n._(t`Poster un besoin`)
              : i18n._(t`Completer mon profil`)
          }
          buttonRedirect={
            role === "CLIENT"
              ? "/dashboard/company/offers"
              : "/dashboard/my-profile"
          }
          history={history}
        />
        <br />
        <div className={classes.horizontalline}></div>
        <div className={classes.container}>
          <div className={classes.leftSide}>
            <div className={classes.title}>
              <Trans>Notes de frais</Trans>
            </div>
            {
              isNaN(Number(match.params.id)) || !Number.isInteger(Number(match.params.id)) ? (
                <div className="error_container">
                  <span>{i18n._(t`Oups! ID invalide (il doit être un entier)`)}</span>
                  <img
                    style={{ margin: 20 }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              ) : (
                <Query
                  query={GET_EXPENSE_REPORT_BY_ID}
                  variables={{ id: Number(match.params.id) }}
                  fetchPolicy="network-only"
                >
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50vh"
                          }}
                        >
                          <Spinner
                            type="pointed-circular"
                            color="#dadee3"
                            size={25}
                          />
                        </div>
                      );
                    if (error) {
                        return (
                          <div className="error_container">
                            <span>
                              {i18n._(t`Oups! Problème lors de l'importation de votre note de frais. Veuillez rafraîchir la page dans un moment.`)}
                            </span>
                            <img
                              style={{
                                margin: 20
                              }}
                              src={"/assets/svg/download.svg"}
                              alt="error"
                            />
                          </div>
                        );
                    }
                    if (!data || !data.getExpenseReportByID) {
                      return (
                        <div className="error_container">
                          <span>
                            {i18n._(
                              t`Aucune note de frais trouvée avec cet ID`
                            )}</span>
                          <img
                            style={{
                              margin: 20
                            }}
                            src={"/assets/svg/download.svg"}
                            alt="error"
                          />
                        </div>
                      );
                    }
                    const expenseReport = data.getExpenseReportByID;
                    const formattedDate = expenseReport.date
                      ? formatDate(expenseReport.date, i18n._(t`fr`))
                      : "";
                    return (
                      <div>
                        <div className={classes.grayRectangle}>
                          {role === "CLIENT" && (
                            <div className={classes.nameAndplacement}>
                              {expenseReport.freelancerExpense.firstName}{" "}
                              {expenseReport.freelancerExpense.lastName}{" "}
                              <Trans>contrat</Trans> #{expenseReport.placementId}
                            </div>
                          )}
                          <div className={classes.cardHeader}>
                            <div className={classes.nameAndDate}>
                              <div className={classes.name}>
                                {expenseReport.title}
                              </div>
                              {role === "CLIENT" ? (
                                <div className={classes.date}>
                                  <Trans>Soumise le</Trans> {formattedDate}
                                </div>
                              ) : role === "CANDIDATE" &&
                                (expenseReport.status === "Draft" ||
                                  expenseReport.status === "Rejected") ? (
                                <div>
                                  <button
                                    className={classes.addExpButton}
                                    onClick={() =>
                                      this.handleAddExpenseClick()
                                    }
                                  >
                                    <img src={"/assets/svg/addexp.svg"} alt="add" />
                                    <span className={classes.addExpTxt}>
                                      <Trans>Dépense</Trans>
                                    </span>
                                  </button>
                                </div>
                              ) : role === "CANDIDATE" &&
                                (expenseReport.status === "Approved" ||
                                  expenseReport.status === "Pending") ? null : null}
                            </div>
                          </div>
                          {expenseReport.expensesDetails.length <= 2 && (
                            <div>
                              {expenseReport.expensesDetails.map(singleExpense => (
                                <div>
                                  <ExpenseCard
                                    key={singleExpense.id}
                                    id={singleExpense.id}
                                    name={singleExpense.name}
                                    date={singleExpense.date}
                                    amount={singleExpense.amount}
                                    tva={singleExpense.tva}
                                    type={singleExpense.type}
                                    receipt={singleExpense.receipt}
                                    receiptName={singleExpense.receiptName}
                                    receiptType={singleExpense.receiptType}
                                    role={role}
                                    status={expenseReport.status}
                                    history={history}
                                    onEditClick={this.handleEditClick}
                                  />
                                  <br />
                                </div>
                              ))}
                            </div>
                          )}
                          {expenseReport.expensesDetails.length > 2 && (
                            <div className={classes.scrollDepense}>
                              {expenseReport.expensesDetails.map(singleExpense => (
                                <div>
                                  <ExpenseCard
                                    key={singleExpense.id}
                                    id={singleExpense.id}
                                    name={singleExpense.name}
                                    date={singleExpense.date}
                                    amount={singleExpense.amount}
                                    tva={singleExpense.tva}
                                    type={singleExpense.type}
                                    receipt={singleExpense.receipt}
                                    receiptName={singleExpense.receiptName}
                                    receiptType={singleExpense.receiptType}
                                    role={role}
                                    status={expenseReport.status}
                                    history={history}
                                    onEditClick={this.handleEditClick}
                                  />
                                  <br />
                                </div>
                              ))}
                            </div>
                          )}
                          <div className={classes.amountAndComment}>
                            {role === "CLIENT" &&
                              (expenseReport.status === "Rejected" ||
                                expenseReport.status === "Approved") ? (
                              <div>
                                <input
                                  type="text"
                                  className={classes.comment}
                                  readOnly
                                  value={
                                    expenseReport.commentaireByFreelancer
                                      ? expenseReport.commentaireByFreelancer
                                      : i18n._(t`Commentaire`)
                                  }
                                />
                              </div>
                            ) : role === "CANDIDATE" &&
                              (expenseReport.status === "Approved" ||
                                expenseReport.status === "Pending") ? (
                              <input
                                type="text"
                                className={classes.comment}
                                value={
                                  expenseReport.commentaireByFirstApproval
                                    ? expenseReport.commentaireByFirstApproval
                                    : i18n._(t`Commentaire`)
                                }
                                readOnly
                              />
                            ) : (
                              <input
                                type="text"
                                className={classes.comment}
                                placeholder={i18n._(t`Commentaire`)}
                                onChange={this.handlecommentaireByFreelancerChange}
                              />
                            )}
                            <div className={classes.amount}>
                              <div className={classes.totalNumber}>
                                <div className={classes.montantTotal}>
                                  <Trans>Montant total</Trans>
                                </div>
                                <span className={classes.amountNumber}>
                                  {expenseReport.totalAmount}€
                                </span>
                              </div>
                              <span className={classes.taux}>
                                TVA {expenseReport.totalTVA}€
                              </span>
                            </div>
                          </div>
                          {role === "CLIENT" ? (
                            <div className={classes.cardFooter}>
                              {expenseReport.status === "Approved" ||
                                expenseReport.status === "Rejected" ? (
                                <div className={classes.cardFooter}>
                                  <span className={classes.expenseStatus}>
                                    {getStatusInTheRightLanguage(
                                      expenseReport.status,
                                      i18n
                                    )}
                                  </span>
                                  <i
                                    className={`fa fa-circle ${classes.expenseIcon}`}
                                    style={{
                                      color: getIconColor(expenseReport.status)
                                    }}
                                  ></i>
                                  <Button
                                    variant="secondary"
                                    style={{
                                      width: "136px",
                                      height: "41px"
                                    }}
                                    onClick={() =>
                                      history.push(`/dashboard/expenses`)
                                    }
                                  >
                                    <Trans>Fermer</Trans>
                                  </Button>
                                </div>
                              ) : (
                                <>
                                  <Mutation mutation={REJECT_EXPENSE}>
                                    {rejectExpenseMutation => (
                                      <>
                                        <button
                                          className={classes.rejeterButton}
                                          onClick={() =>
                                            this.handleRejectExpense(
                                              rejectExpenseMutation
                                            )
                                          }
                                        >
                                          <Trans>Rejeter</Trans>
                                        </button>
                                      </>
                                    )}
                                  </Mutation>
                                  <Mutation mutation={ACCEPT_EXPENSE}>
                                    {acceptExpenseMutation => (
                                      <>
                                        <button
                                          className={classes.validerButton}
                                          onClick={() =>
                                            this.handleAcceptExpense(
                                              acceptExpenseMutation
                                            )
                                          }
                                        >
                                          <Trans>Valider</Trans>
                                        </button>
                                      </>
                                    )}
                                  </Mutation>
                                </>
                              )}
                            </div>
                          ) : (
                            <div className={classes.cardFooter}>
                              <span className={classes.expenseStatus}>
                                {getStatusInTheRightLanguage(
                                  expenseReport.status,
                                  i18n
                                )}
                              </span>
                              <i
                                className={`fa fa-circle ${classes.expenseIcon}`}
                                style={{
                                  color: getIconColor(expenseReport.status)
                                }}
                              ></i>
                              {expenseReport.status === "Approved" ||
                                expenseReport.status === "Pending" ? (
                                <Button
                                  variant="secondary"
                                  style={{
                                    width: "136px",
                                    height: "41px"
                                  }}
                                  onClick={() =>
                                    history.push(`/dashboard/expenses`)
                                  }
                                >
                                  <Trans>Fermer</Trans>
                                </Button>
                              ) : (
                                <Mutation mutation={SEND_EXPENSE}>
                                  {sendExpenseMutation => (
                                    <>
                                      <button
                                        className={classes.validerButton}
                                        onClick={() =>
                                          this.handleSendExpense(
                                            sendExpenseMutation
                                          )
                                        }
                                      >
                                        <Trans>Envoyer</Trans>
                                      </button>
                                    </>
                                  )}
                                </Mutation>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </Query>
              )
            }

          </div>
          <div className={classes.rightSide}>
            {role === "CANDIDATE" && (
              <>
                {showExpenseForm ? (
                  // Formulaire d'ajout d'une depense
                  <div>
                    <Icon
                      className={classes.closeIcon}
                      onClick={this.handleCloseFormClick}
                      type="close"
                      color="#000000"
                      color2="#000000"
                      width={16}
                      height={16}
                    />
                    <AddSingleExpenseForm
                      i18n={i18n}
                      id={Number(match.params.id)}
                      expense={selectedExpense}
                      onExpenseSubmitted={this.handleAddExpenseClick}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
ExpenseReport.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number])
  }).isRequired
};
export default withI18n()(withRouter(ExpenseReport));
