import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { Mutation } from "react-apollo";
import { ADD_SINGLE_EXPENSE, EDIT_SINGLE_EXPENSE } from "../single-expense-report-page/mutations";
import { Button, FormGroup, Select, TextInput, Validation, toaster } from "cf-neo-ui";
import validate from "../../../utils/validators";
import "./addSingleExpenseForm.css";
import { GET_EXPENSE_REPORT_BY_ID } from './../single-expense-report-page/queries';
import moment from "moment";

const AddSingleExpenseForm = ({ i18n, id, expense, onExpenseSubmitted }) => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [amount, setAmount] = useState("");
  const [tva, setTva] = useState("");
  const [type, setType] = useState(null);
  const [comment, setComment] = useState("");
  const [receipt, setReceipt] = useState(null);
  const [receiptName, setReceiptName] = useState("");

  const [isAmountValid, setIsAmountValid] = useState(true);
  const [amountValidationMessage, setAmountValidationMessage] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [nameValidationMessage, setNameValidationMessage] = useState("");
  const [isDateValid, setIsDateValid] = useState(true);
  const [dateValidationMessage, setDateValidationMessage] = useState("");

  useEffect(() => {
    if (expense) {
      setName(expense.name || "");
      setDate(moment(expense.date).format("YYYY-MM-DD") || "");
      setLocation(expense.location || "");
      setAmount(expense.amount || 0);
      setTva(expense.tva || 0);
      setType(expense.type || null);
      setComment(expense.comment || "");
      setReceipt(expense.receipt || null);
      setReceiptName(expense.receiptName || "");
    }
  }, [expense]);

  const handleReceiptChange = (event) => {
    const file = event.target.files[0];
    setReceipt(file);
    setReceiptName(file ? file.name : "");
  };
  const resetFields = () => {
    setName("");
    setDate("");
    setLocation("");
    setAmount("");
    setTva("");
    setComment("");
    setReceipt(null);
    setReceiptName("");
    setType(null)
  };
  const handleAddOrEditExpense = async (mutation) => {
    if (!validateName(name) || !validateDate(date) || !validateAmount(amount)) {
      return;
    }
    let tvaValue = tva === "" ? 0 : tva;
    let amountValue = amount === "" ? 0 : amount;
    const variables = {
      name,
      date,
      location,
      amount: parseFloat(amountValue),
      tva: parseFloat(tvaValue),
      type,
      comment,
      receipt,
    };
    try {
      if (expense) {
        await mutation({
          variables: {
            id: expense.id,
            ...variables,
          },
        });
        toaster.success({
          title: i18n._(t`Dépense modifiée avec succès!`),
        });
        resetFields();
        onExpenseSubmitted();// Appel de la fonction pour réinitialiser `expense` dans le composant parent(ExpenseReport)
      } else {
        await mutation({
          variables: {
            expenseReportId: id,
            ...variables,
          },
        });
        resetFields();
        onExpenseSubmitted();
        toaster.success({
          title: i18n._(t`Dépense ajoutée avec succès!`),
          description: i18n._(t`Vous pouvez la consulter et la modifier à tout moment.`),
        });
      }
    } catch (error) {
      toaster.error({
        title: i18n._(t`Erreur lors de la mise à jour`),
        description: i18n._(t`Erreur lors de la mise à jour de dépense, veuillez réessayer plus tard.`),
      });
    }
  };

  const validateAmount = (value) => {
    const res = validate(value, ["required", "number", { maxlength: 5 }, { minlength: 1 }], i18n);
    setIsAmountValid(res.isValid);
    setAmountValidationMessage(res.message);
    return res.isValid;
  };

  const validateName = (value) => {
    const res = validate(value, ["required"], i18n);
    setIsNameValid(res.isValid);
    setNameValidationMessage(res.message);
    return res.isValid;
  };

  const validateDate = (value) => {
    const res = validate(value, ["required"], i18n);
    setIsDateValid(res.isValid);
    setDateValidationMessage(res.message);
    return res.isValid;
  };

  const typeOptions = {
    groupA: [
      { text: i18n._(t`Accommodation`), value: "Accommodation" },
      { text: i18n._(t`Business Trip`), value: "BusinessTrip" },
      { text: i18n._(t`Meal`), value: "Meal" },
    ],
  };

  return (
    <Mutation
      mutation={expense ? EDIT_SINGLE_EXPENSE : ADD_SINGLE_EXPENSE}
      refetchQueries={[
        {
          query: GET_EXPENSE_REPORT_BY_ID,
          variables: { id },
        },
      ]}
    >
      {(mutation) => (
        <FormGroup>
          <div className="formDepense">
            <div style={{ marginBottom: "6px" }} className="ajouter">
              {expense ? <Trans>Editer une dépense</Trans> : <Trans>Ajouter une dépense</Trans>}
            </div>
            <Validation
              errorMessage={nameValidationMessage}
              valid={isNameValid}
            >
              <TextInput
                placeholder={i18n._(t`Titre de dépense`)}
                size="normal"
                className="TextInput"
                value={name}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setName(newValue);
                  validateName(newValue);
                }}
              />
            </Validation>
            <Validation errorMessage={dateValidationMessage} valid={isDateValid}>
              <div className="containerInput">
                <div className="inputWrapper">
                  <TextInput
                    style={{
                      fontWeight: "normal",
                      color: date ? "black" : "#c4c8cc",
                    }}
                    type="date"
                    className="dateInput"
                    value={date}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setDate(newValue);
                      validateDate(newValue);
                    }}
                  />
                  <img
                    src="/assets/svg/calendar.svg"
                    alt="calendar"
                    className="inputIcon"
                  />
                </div>
              </div>
            </Validation>
            <div className="containerInput">
              <TextInput
                placeholder={i18n._(t`Lieu`)}
                size="normal"
                className="TextInput"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              <img
                src={"/assets/svg/location.svg"}
                alt="location"
                className="inputIcon"
              />
            </div>
            <Validation
              errorMessage={amountValidationMessage}
              valid={isAmountValid}
            >
              <div className="containerInput">
                <TextInput
                  placeholder={i18n._(t`Montant`)}
                  size="normal"
                  className="TextInput"
                  value={amount}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setAmount(newValue);
                    validateAmount(newValue);
                  }}
                />
                <img
                  src={"/assets/svg/euro.svg"}
                  alt="location"
                  className="inputIcon"
                />
              </div>
            </Validation>
            <div className="containerInput">
              <TextInput
                placeholder={i18n._(t`TVA`)}
                size="normal"
                className="TextInput"
                value={tva}
                onChange={(e) => setTva(e.target.value)}
              />
              <img
                src={"/assets/svg/euro.svg"}
                alt="location"
                className="inputIcon"
              />
            </div>
            <Select
              className="select"
              style={{ marginBottom: "6px" }}
              placeholder={i18n._(t`Type de dépense`)}
              id="selectedItem"
              options={typeOptions}
              value={type}
              onSelect={(option) => setType(option.value)}
            />
            <TextInput
              style={{ height: "50px", marginBottom: "16px" }}
              placeholder={i18n._(t`Commentaire`)}
              size="normal"
              className="TextInput"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="customFileInput">
              <img src={"/assets/svg/add.svg"} alt="add" className="addIcon" />
              <label htmlFor="files">
                {receiptName ? receiptName : <Trans>Ajouter un reçu</Trans>}
              </label>
              <input
                id="files"
                type="file"
                accept=".doc,.docx,.pdf,.png,.jpeg,.jpg"
                onChange={handleReceiptChange}
              />
            </div>
            <br />
            <div className="centeredButton">
              <Button
                disabled={!isNameValid || !isDateValid || !isAmountValid}
                variant="secondary"
                onClick={() => handleAddOrEditExpense(mutation)}
              >
                <Trans>Valider dépense</Trans>
              </Button>
            </div>
          </div>
        </FormGroup>
      )}
    </Mutation>
  );
};

AddSingleExpenseForm.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.number,
  expense: PropTypes.object,
};

export default AddSingleExpenseForm;
