import gql from "graphql-tag";
export const GET_EXPENSES_QUERY = gql`
query {
  expenses {
      id
      title
      date
      placementId
      comment
      totalAmount
      totalTVA
      status
      createdAt
      expenses{
        id
      }
  }
}
`;

export const GET_MY_EXPENSES_QUERY = gql`
  query MyExpenses($email: String!, $role: String!) {
    myexpenses(email: $email, role: $role) {
      
        id
        placementId
        clientExpense {
          id
          email
          firstName
          lastName
        }
        freelancerExpense {
          id
          email
          firstName
          lastName
        }
        firstApproval {
          id
          email
          firstName
          lastName
        }
        title
        date
        comment
        totalAmount
        totalTVA
        status
        createdAt
      
    }
  }
`;

export const GET_MY_EXPENSES_FilteredByStatus_QUERY = gql`
  query MyExpenses($email: String!, $role: String!, $status: String) {
    myexpensesFilteredByStatus(email: $email, role: $role, status: $status) {
      
        id
        placementId
        clientExpense {
          id
          email
          firstName
          lastName
        }
        freelancerExpense {
          id
          email
          firstName
          lastName
        }
        firstApproval {
          id
          email
          firstName
          lastName
        }
        title
        date
        comment
        totalAmount
        totalTVA
        status
        createdAt
      
    }
  }
`;

export const SEARCH_EXPENSES_QUERY = gql`
  query SearchExpenses($searchQuery: String, $email: String!, $role: String!) {
    search(searchQuery: $searchQuery, email: $email, role: $role) {
      
        id
        placementId
        clientExpense {
          id
          email
          firstName
          lastName
        }
        freelancerExpense {
          id
          email
          firstName
          lastName
        }
        firstApproval {
          id
          email
          firstName
          lastName
        }
        title
        date
        comment
        totalAmount
        totalTVA
        status
        createdAt
      
    }
  }
`;


export const My_NEW_EXPENSES_QUERY = gql`
  query NewExpenses($emailClient: String!) {
    mynewexpenses(emailClient: $emailClient) {
      
        id
        placementId
        clientExpense {
          id
          email
          firstName
          lastName
        }
        freelancerExpense {
          id
          email
          firstName
          lastName
        }
        firstApproval {
          id
          email
          firstName
          lastName
        }
        title
        date
        comment
        totalAmount
        totalTVA
        status
        createdAt
      
    }
  }
`;

export const ACTIVE_PLACEMENTS_QUERY = gql`
  query ActivePlacements($email: String!) {
    myactiveplacements(email: $email) {
      id
      jobOrder {
        id
        title
      }
      status
    }
  }
`;