import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  TextInput,
  Select,
  Icon,
  InputSkeleton,
  NotificationCard
} from "cf-neo-ui";
import ExpenseReportCard from "../expense-report-card/ExpenseReportCard";
import { Query, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import classes from "../styles.module.scss";
import {
  SEARCH_EXPENSES_QUERY,
  GET_MY_EXPENSES_FilteredByStatus_QUERY,
  My_NEW_EXPENSES_QUERY,
  GET_MY_EXPENSES_QUERY,
  ACTIVE_PLACEMENTS_QUERY
} from "./queries";
import AddExpenseReportForm from "../add-expense-report-form/AddExpenseReportForm";

@inject("appStore", "sessionStore")
@observer
class FilteredExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusFilter: "",
      searchQuery: "",
      showExpenseForm: false,
      showNotificationCard: true,
      placementOptions: { groupA: [] },
      loadingPlacements: true,
      errorPlacements: null,
    };
  }

  componentDidMount() {
    //lancer la requete de recherche des missions actives que role CLIENT
    const { sessionStore } = this.props;
    const role = sessionStore.account.role;
    if (role === "CANDIDATE") {
      this.fetchActivePlacements();
    }
  }

  fetchActivePlacements = async () => {
    //lancer la requete des missions actives pour les passer dans la formulaire d'ajout d'une note de frais
    const { client, sessionStore } = this.props;
    const email = sessionStore.account.email;
    try {
      const { data } = await client.query({
        query: ACTIVE_PLACEMENTS_QUERY,
        variables: { email },
        fetchPolicy: "network-only"
      });
      const activePlacements = data?.myactiveplacements || [];
      const placementOptions = {
        groupA: activePlacements.map((placement) => ({
          text: placement.id,
          value: placement.id
        }))
      };
      this.setState({ placementOptions, loadingPlacements: false });
    } catch (error) {
      this.setState({ errorPlacements: error, loadingPlacements: false });
    }
  };

  handleAddExpenseClick = () => {
    this.setState({ showExpenseForm: true });
  };

  handleCloseFormClick = () => {
    this.setState({ showExpenseForm: false });
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleCloseNotificationCard = () => {
    this.setState({ showNotificationCard: false });
  };

  render() {
    const { sessionStore, i18n, history } = this.props;
    const role = sessionStore.account.role;
    const email = sessionStore.account.email;
    const { showExpenseForm, showNotificationCard, placementOptions, loadingPlacements, errorPlacements } = this.state;

    const statusOptions = {
      groupA: [
        {
          text: i18n._(t`Pending`),
          value: "Pending",
          ...(this.state.statusFilter === i18n._(t`Pending`) && { selected: true })
        },
        {
          text: i18n._(t`Approved`),
          value: "Approved",
          ...(this.state.statusFilter === i18n._(t`Approved`) && { selected: true })
        },
        {
          text: i18n._(t`Rejected`),
          value: "Rejected",
          ...(this.state.statusFilter === i18n._(t`Rejected`) && { selected: true })
        },
        {
          text: i18n._(t`Tous`),
          value: "",
          ...(this.state.statusFilter === "" && { selected: true })
        }
      ]
    };

    if (role !== "CLIENT") {
      statusOptions.groupA.unshift({
        text: i18n._(t`Draft`),
        value: "Draft",
        ...(this.state.statusFilter === i18n._(t`Draft`) && { selected: true })
      });
    }

    return (
      <div>
        <div className={classes.horizontalline}></div>
        <div className={classes.container}>
          <div className={classes.leftSide}>
            <div>
              <div className={classes.title}>
                <Trans>Notes de Frais</Trans>
              </div>
              <div
                className={`${classes.actionContainer} ${role === "CLIENT" ? classes.clientAlignRight : ""}`}
              >
                {role === "CANDIDATE" && (
                  <button
                    className={classes.addButton}
                    onClick={this.handleAddExpenseClick}
                  >
                    <img src={"/assets/svg/add.svg"} alt="add" />
                    <span className={classes.addTxt}>
                      <Trans>Note de frais</Trans>
                    </span>
                  </button>
                )}
                <div className={classes.searchFilterContainer}>
                  <div className={classes.NfSearch}>
                    <TextInput
                      placeholder={i18n._(t`Chercher une note de frais`)}
                      size="normal"
                      icon="search"
                      className={classes.SearchInput}
                      iconColor={"#8D0417"}
                      onChange={this.handleSearchChange}
                    />
                  </div>
                  <div className={classes.filter}>
                    <Select
                      placeholder={i18n._(t`Statut`)}
                      options={statusOptions}
                      onSelect={(e) => {
                        this.setState({ statusFilter: e.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <Query query={GET_MY_EXPENSES_QUERY} variables={{ email, role }} fetchPolicy="network-only">
              {({ loading, error, data }) => {
                if (loading) return <InputSkeleton />;
                if (error) {
                  return (
                    <div className="error_container">
                      <span>
                        {i18n._(t`Oups! Problème lors de l'importation de vos notes de frais. Veuillez rafraîchir la page dans un moment.`)}
                      </span>
                      <img
                        style={{
                          margin: 20
                        }}
                        src={"/assets/svg/download.svg"}
                        alt="error"
                      />
                    </div>
                  );
                }
                if (role === "CANDIDATE" && (!data.myexpenses || data.myexpenses.length === 0)) {
                  return (
                    <div>
                      <p>
                        <br />
                        <br />
                        <Trans>Vous n’avez pas encore de note de frais</Trans>
                        <br />
                        <br />
                        <Trans>
                          Ajoutez une note de frais puis créez des dépenses.
                        </Trans>{" "}
                        <br />
                        <Trans>
                          Le montant total sera calculé automatiquement.
                        </Trans>
                        <br />
                        <br />
                      </p>
                      <div style={{ marginTop: "70px", marginLeft: "320px" }}>
                        <button
                          className={classes.addButton}
                          onClick={this.handleAddExpenseClick}
                        >
                          <img src={"/assets/svg/add.svg"} alt="add" />
                          <span className={classes.addTxt}>
                            <Trans>Note de frais</Trans>
                          </span>
                        </button>
                      </div>
                    </div>
                  );
                }
                if (role === "CLIENT" && (!data.myexpenses || data.myexpenses.length === 0)) {
                  return (
                    <div style={{ textAlign: "center", margin: "auto" }}>
                      <p>
                        <br />
                        <br />
                        <Trans>
                          Vous n’avez pas encore de note de frais à rembourser.
                        </Trans>
                        <br />
                        <br />
                      </p>
                    </div>
                  );
                }
                return (
                  <Query
                    query={this.state.searchQuery ? SEARCH_EXPENSES_QUERY : GET_MY_EXPENSES_FilteredByStatus_QUERY}
                    variables={{
                      role,
                      email,
                      status: this.state.statusFilter,
                      searchQuery: this.state.searchQuery
                    }}
                    fetchPolicy="network-only"
                  >
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error) {
                        return (
                          <div className="error_container">
                            <span>
                              {i18n._(t`Oups! Problème lors de l'importation de vos notes de frais. Veuillez rafraîchir la page dans un moment.`)}
                            </span>
                            <img
                              style={{
                                margin: 20
                              }}
                              src={"/assets/svg/download.svg"}
                              alt="error"
                            />
                          </div>
                        );
                      }
                      return (
                        <div className={classes.scrollExpense}>
                          <div className={classes.pageBody}>
                            {data.myexpensesFilteredByStatus
                              ? data.myexpensesFilteredByStatus.map(
                                (expense) => (
                                  <div key={expense.id}>
                                    <ExpenseReportCard
                                      {...expense}
                                      role={role}
                                      email={email}
                                    />
                                    <br />
                                  </div>
                                )
                              )
                              : data.search
                                ? data.search.map((expense) => (
                                  <div key={expense.id}>
                                    <ExpenseReportCard
                                      {...expense}
                                      role={role}
                                    />
                                    <br />
                                  </div>
                                ))
                                : null}
                          </div>
                        </div>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          </div>
          <div className={classes.rightSide}>
            {role === "CLIENT" && (
              <Query
                query={My_NEW_EXPENSES_QUERY}
                variables={{ emailClient: email }}
                fetchPolicy="network-only"
              >
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) {
                    return (
                      <div className="error_container">
                      </div>
                    );
                  }

                  const newExpenses = data.mynewexpenses;

                  if (newExpenses.length === 0) {
                    return null; // Pas de nouvelles dépenses, ne rend rien
                  }

                  return (
                    <div>
                      <br />
                      <br />
                      <br />
                      <div className={classes.notificationCardContainer}>
                        {newExpenses.map((expense) => (
                          <div
                            key={expense.id}
                            className={classes.notificationCard}
                          >
                            {showNotificationCard && (
                              <NotificationCard
                                key={expense.id}
                                onCloseCard={this.handleCloseNotificationCard}
                                onClickAction={() =>
                                  history.push(
                                    `/dashboard/expenses/${expense.id}`
                                  )
                                }
                                mainIconType="grid"
                                title={i18n._(t`Nouvelle note de frais`)}
                                content={`${i18n._(
                                  t`Vous avez une nouvelle note de frais pour`
                                )} ${expense.freelancerExpense.firstName} ${expense.freelancerExpense.lastName
                                  } ${i18n._(t`contrat`)} ${expense.placementId}`}
                                buttonText={i18n._(t`Consulter`)}
                                style={{ marginBottom: "15px" }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }}
              </Query>
            )}
            {role === "CANDIDATE" && (
              <>
                {showExpenseForm ? (
                  <div>
                    <Icon
                      type="close"
                      color="#000000"
                      color2="#000000"
                      width={16}
                      height={16}
                      className={classes.closeIcon}
                      onClick={this.handleCloseFormClick}
                    />
                    <AddExpenseReportForm
                      i18n={i18n}
                      email={email}
                      role={role}
                      history={history}
                      placementOptions={placementOptions}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FilteredExpenses.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number,
    changApproved: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  client: PropTypes.object.isRequired,
};

export default withI18n()(withRouter(withApollo(FilteredExpenses)));