import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react/index";
import { inject, observer } from "mobx-react";
import Article from "./article/Article";
import Loading from "./LoadingSingleBlog/Loading";
import { SinglePostQuery } from "../query";
import decode from "../../../utils/Utf8Text";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";
import { withRouter } from "react-router-dom";

@inject("appStore")
@observer
class BlogSinglePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { match, i18n, appStore, history } = this.props;
    const { currentLanguage } = appStore;
    let language = new URLSearchParams(this.props.location.search).get(
      "language"
    );
    if (language && currentLanguage !== language)
      appStore.changeLanguage(language);

    const { id, title } = match.params;

    return (
      <Query
        query={SinglePostQuery}
        variables={{
          id,
          language: language || currentLanguage
        }}
      >
        {({ loading, error, data }) => {
          if (error || (data && !data.post)) {
            // Handle error
            return (
              <div className="error_container">
                <span>
                  {i18n._(
                    t`Oups! il semblerait que vos données aient du mal à se synchroniser. Veuillez rafraîchir la page dans un moment`
                  )}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          }

          if (loading) {
            // Handle loading state
            return (
              <div>
                <Loading />
              </div>
            );
          }

          const { post } = data;

          // If title is missing in URL, redirect to URL with title
          if (!title) {
            const urlFriendlyTitle = encodeURIComponent(
              post.title
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")
            );
            history.replace(`/blog/news/${id}/${urlFriendlyTitle}`);
            return null; // Render nothing while redirecting
          }

          // Render article
          return (
            <>
              {metaTags(
                urls.singleBlog(
                  post.id,
                  language || currentLanguage,
                  post.title
                ),
                decode(post.title) + " - Mindquest",
                decode(post.meta_description),
                post.featured_media.source_url,
                post.featured_media.source_url,
                decode(post.meta_keys),
                ldJson(i18n, post),
                language || currentLanguage,
                language && currentLanguage !== language ? false : true
              )}
              <Article
                id={post.id}
                categories={post.categories}
                tags={post.tags}
                title={decode(post.title)}
                cover={
                  post.featured_image2_url ||
                  (post.featured_media && post.featured_media.source_url)
                }
                blogger={post.author}
                addDate={post.date}
                updateDate={post.modified}
                content={post.content}
                relatedPosts={post.related_posts}
              />
            </>
          );
        }}
      </Query>
    );
  }
}

BlogSinglePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string
    })
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default withI18n()(withRouter(BlogSinglePage));
